import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'

import './styles.scss'
import Input from '../../components/Input'
import { login } from '../../store/slices/identity'

import ComounaLogo from '../../assets/img/logo3.svg'

function Login({ navigation }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isAuth, isLoading, error } = useSelector((state) => state.identity)
  const [showError, setShowError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { handleSubmit, control } = useForm()

  useEffect(() => {
    if (isAuth) {
      navigate('/')
    }
  }, [isAuth, navigate])

  useEffect(() => {
    if (error) {
      setShowError(error)
    }
  }, [setShowError, error])

  const onSubmit = (data) => {
    const { username, password } = data
    dispatch(login({ username, password }))
  }
  
  return (
    <Box display="flex" className="container">
      <Box className="login-form" component="form" >
        {/* <h2>Iniciar sesión</h2> */}
        <img src={ComounaLogo} alt="Comouna Logo" style={{ width: '90px', height: '90px'}} />

        <div className='container-form'>
          <Input
            fullWidth
            label="Teléfono"
            name="username"
            rules={{ required: true }}
            control={control}
          />

          <Input
            fullWidth
            label="Contraseña"
            type={showPassword ? 'text' : 'password'}
            name="password"
            rules={{ required: true }}
            control={control}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="borrar valor"
                  onClick={() => setShowPassword(prev => !prev)}
                  edge="end"
                >
                  {!showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>

        {showError && (
          <div className='login-error'>
            <CloseIcon/> <span>Credenciales inválidas.</span>
          </div>
        )}
        
        {isLoading &&
          <Box sx={{ display: 'flex', width: '100%', height: '100px', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        }

        <Button className='btn-main' onClick={handleSubmit(onSubmit)} fullWidth variant="contained" disabled={isLoading}>
          Iniciar sesion
        </Button>

        {process.env.REACT_APP_ENV &&
          <div className='text-center' style={{ marginTop: '30px'}}>
            <p>{process.env.REACT_APP_ENV}</p>
          </div>
        }
      </Box>
    </Box>
  )
}

export default Login