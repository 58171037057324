import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import TuneIcon from '@mui/icons-material/Tune'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import { getFilteredStores } from 'store/slices/stores'
import { logout } from 'store/slices/identity'
import Input from 'components/Input'
import { Container } from './styles'

function SearchBar() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { filter_inventory_state, filter_zone } = useSelector(state => state.stores)

  const onSubmit = (data) => {
    console.log(data); // Handle search logic here
    dispatch(getFilteredStores({
      name: data?.name,
      inventory_state: filter_inventory_state,
      zone: filter_zone
    }))
  }

  // const handleSearch = (prevValue) => !prevValue;
 
  return (
    <Container>
      <div style={{ flex: 1 }}>
        <Input
          name="name"
          control={control}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="buscar tienda"
                onClick={handleSubmit(onSubmit)}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      {/* <IconButton icon="tune" onClick={() => navigate('/Filters')}>
        <TuneIcon />
      </IconButton> */}
      <IconButton icon="exit-to-app" onClick={() => dispatch(logout())}>
        <ExitToAppIcon />
      </IconButton>
    </Container>
  )
}

export default SearchBar
