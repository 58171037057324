import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Box } from '@mui/material'

import StoresList from '../../components/StoresList'
import { getStores } from '../../store/slices/stores'
import SearchBar from 'components/SearchBar'

import { getVendor } from '../../store/slices/vendor'
import { clearStoreState } from '../../store/slices/store'
import { clearShelfState } from '../../store/slices/shelf'

function Home() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const { stores } = useSelector((state) => state.stores)

  // useEffect(() => {
  //   dispatch(getStores())
  // }, [])

  const { stores, filter_inventory_state, filter_zone } = useSelector(state => state.stores)
  const store = useSelector(state => state.store)
  const { isLoading, error } = useSelector(state => state.vendor)

  useEffect(() => {
    if (filter_inventory_state === 'all' && filter_zone?.length === 0) {
      console.log('getStores()')
      dispatch(getStores())
    }
  }, [filter_zone, filter_inventory_state])

  useEffect(() => {
    console.log('GET VENDOR')
    // getCoords()
    dispatch(getVendor())
    dispatch(clearStoreState())
    dispatch(clearShelfState())
    // app.getDeviceInfo()
  }, [])

  return (
    <div className="container">
      <SearchBar />
      {isLoading &&
        <Box sx={{ display: 'flex', width: '100%', height: '300px', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      }
      {error && 
        <div style={{ padding: 14 }}>
          <div>Error:</div>
          <div>{error}</div>
        </div>
      }
      <StoresList navigation={{ navigate }} stores={stores} />
    </div>
  )
}

export default Home