import { Provider } from 'react-redux'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { PersistGate } from 'redux-persist/integration/react'
// import { purple } from '@mui/material/colors'

import { store, persistor } from './store'
import AppRouter from './router/index'
import colors from './styles/colors'

import './App.scss'

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: '#f44336',
    },
    colors: colors
  },
})

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <AppRouter /> 
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App