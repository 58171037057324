import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'


import sharedStyles, {
  Header,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
  Divider,
  DataWrapper
} from '../../styles/shared'
import BackButton from '../../components/BackButton'
import styles from './styles' 
import InventoryProduct from '../../components/InventoryProduct'
import {
  getShelf,
  saveReceipt,
  // setProductPosition,
  setInitImage,
  clearRestockSteps,
  setSelectProduct
} from '../../store/slices/shelf'
import { CAMERA_TYPES } from '../../constants/types'
import ImageThumbnail from '../../components/ImageThumbnail'
import colors from '../../styles/colors'

function ConsignmentDetail() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { name, shelf_number, address, id: store_id } = useSelector(state => state.store)
  const {
    shelf_init_image,
    products,
    isLoading,
    products_restock,
    receiptIsLoading,
    receipt: { id: receipt_id },
    receiptError
  } = useSelector(state => state.shelf)
  const { state, municipality, neighborhood, street, ext_number } = address || {}
  const [restockProducts, setRestockProducts] = useState(null)
  const [recallProducts, setRecallProducts] = useState(null)

  useEffect(() => {
    if (shelf_number && !products?.length) {
      dispatch(getShelf({ shelf_number }))
    }
  }, [shelf_number, products])

  useEffect(() => {
    if (receipt_id) {
      navigate('/ShelfReceipt')
    }
  }, [receipt_id])

  useEffect(() => {
    if (products?.length) {
      const restock = products?.filter(product => product?.restock)
      const recall = products?.filter(product => !product?.restock)
      setRestockProducts(restock?.length ? restock : null)
      setRecallProducts(recall?.length ? recall : null)
    }
  }, [products])


  const countFilledProducts = () => {
    const filled = products_restock ? Object.values(products_restock)?.filter(product => product.units)?.length : 0
    return filled < products?.length
  }

  return (
    <div className="container">
      <Header padding={{ b: 46 }}>
        <HeaderLeft>
          <BackButton onPress={() => navigate(-1)} />
        </HeaderLeft>
        <HeaderCenter>
          <span className="bodyLarge">
            Detalle de Tienda
          </span>
        </HeaderCenter>
        <HeaderRight>
        </HeaderRight>
      </Header>
      <Box className="main">
        <Box className="bodyLarge" style={{ color: colors.deepblue }} mb={2}>
          {name}
        </Box>
        <span className="bodyMedium">
          {`${street} ${ext_number}, ${neighborhood}, ${municipality}, ${state}`}
        </span>
        <Divider margin={{ t: 16, b: 16 }} color={colors.midgrey} />
        {restockProducts &&
          <>
            <Box mb={2}>
              <span className="bodyLarge" style={styles.restockTitle}>
                Producto a resurtir
              </span>
            </Box>
            {restockProducts?.map(product => (
              <InventoryProduct
                key={product.sku} 
                product={product}
                radius={10} 
                margin={{ b: 16 }}
                onPress={() => {
                  dispatch(clearRestockSteps())
                  dispatch(setSelectProduct(product))
                  navigate('/Restock')
                }}
              />
            ))}
          </>
        }
        {recallProducts && 
          <>
            <Box mb={2}>
              <span className="bodyLarge" style={styles.recallTitle}>
                Producto a retirar
              </span>
            </Box>
            {recallProducts?.map(product => (
              <InventoryProduct
                key={product.sku} 
                product={product} 
                radius={10} 
                margin={{ b: 16 }}
                onPress={() => {
                  dispatch(clearRestockSteps())
                  dispatch(setSelectProduct(product))
                  navigate('/Recall')
                }}
              />
            ))}
          </>
        }
        {!isLoading && !products?.length && (
          <span variant="bodyMedium" style={sharedStyles.textCenter}>
            No hay productos para mostrar
          </span>
        )}
        {isLoading && (
          <Box sx={{ display: 'flex', width: '100%', height: '300px', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {/* {!shelf_init_image ? (
          <Button
            icon="camera" 
            style={styles.cameraBtn}
            textColor={colors.deepblue}
            onPress={() =>
              navigate('/CaptureImage', { type: CAMERA_TYPES.INITIAL_IMAGE })
            }
          >
            Tomar foto de Anaquel (opcional)
          </Button>
        ) : (
          <ImageThumbnail uri={shelf_init_image?.url} name={shelf_init_image?.name} onPress={() => dispatch(setInitImage(null))} />
        )} */}
        <Box mt={4}>
          <LoadingButton
            variant="contained"
            style={styles.secondaryBtn}
            disabled={receiptIsLoading || isLoading}
            loading={receiptIsLoading}
            fullWidth
            loadingPosition="start"
            onClick={() => {
              dispatch(saveReceipt({
                store_id: store_id,
                shelf_number: shelf_number,
                order_details: Object.values(products_restock)
              }))
            }}
          >
            Generar recibo
          </LoadingButton>
          {receiptError &&
            <span variant="bodySmall" style={{ textAlign: 'center', color: colors.error }}>
              {'\n'}{receiptError}
            </span>
          }
        </Box>
      </Box>
    </div>
  )
}

export default ConsignmentDetail
