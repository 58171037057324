import styled from 'styled-components'
import { COLORS } from '../../styles/colors'

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  padding: 16px;
  /* border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.midgrey}; */
  border-radius: ${props => props.radius || 0}px;

  margin-bottom: ${props => props.margin?.b || 0}px;
  margin-top: ${props => props.margin?.t || 0}px;
  margin-left: ${props => props.margin?.l || 0}px;
  margin-right: ${props => props.margin?.r || 0}px;
`

const styles = {
  image: {
    width: 90,
    height: 90,
    borderRadius: 8,
    marginRight: 16,
  }
}

export default styles
