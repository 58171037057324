import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import SuccessIcon from 'assets/img/Success.svg'
import ErrorIcon from 'assets/img/Error.svg'
import {
  Header,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
} from 'styles/shared'
import styles, {
  TransactionWrapper,
  ImageWrapper,
  CenterWrapper,
} from './styles'
import StarsRate from 'components/StarsRate'
import { rateConsignment, setFinishImage, resetReceipt, updateReceiptById } from 'store/slices/shelf'
import Input from 'components/Input'
import { CAMERA_TYPES, RECEIPT_STATUS } from 'constants/types'
import ImageThumbnail from 'components/ImageThumbnail'
import { removeNotification } from 'services/notifications'
import PlaceholderNPS from 'assets/PlaceholderNPS'
import { MOCKED_USER } from 'constants/info'
import colors from 'styles/colors'

function NPS() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    rateIsLoading, 
    rateError, 
    rateSuccess, 
    receipt, 
    notification_key, 
    notification_path,
    shelf_finish_image,
    shelf_init_image,
  } = useSelector(state => state.shelf)
  const { user_name } = useSelector(state => state.vendor)
  const { status, id: receipt_id, store_logo } = receipt || {} 
  const [step, setStep] = useState(1)
  const { control, handleSubmit } = useForm()
  const { store_name } = useSelector(state => state.store)
  const [rate, setRate] = useState(0)

  useEffect(() => {
    if (rateSuccess) {
      setStep(5)
    } else if (shelf_finish_image) {
      setStep(2)
    }
  }, [rateSuccess, shelf_finish_image])

  const onSubmit = (data) => {
    dispatch(rateConsignment({
      consignment_id: receipt_id,
      rate,
      attribute: '',
      comments: data?.comments
    }))
  }

  const goHome = () => {
    removeNotificationHandler()
    navigate('/')
  }

  const backToDetails = () => {
    dispatch(resetReceipt())
    removeNotificationHandler()
    navigate('/ConsignmentDetail')
  }

  const removeNotificationHandler = () => {
    removeNotification({
      notification_key,
      path: notification_path
    })
  }

  const handleSendImage = () => {
    dispatch(updateReceiptById({
      consignment_id: receipt_id, 
      data: {
        images: [
          {
            url: shelf_init_image?.url,
            name: 'shelf_init_image'
          },
          {
            url: shelf_finish_image?.url,
            name: 'shelf_finish_image'
          }
        ]
      }
    }))
  }

  // const isMocked = user_name === MOCKED_USER

  // const error = (status !== RECEIPT_STATUS.ACCEPTED && status !== RECEIPT_STATUS.COMPLETED) ? 'La orden ha sido rechazada' : null
  // console.log({ error, rateError, status })

  const isMocked = user_name === MOCKED_USER
  const isAccepted = [RECEIPT_STATUS.ACCEPTED, RECEIPT_STATUS.COMPLETED, RECEIPT_STATUS.FULFILLED].includes(status) || isMocked

  if (isAccepted && !rateError) {
    return (
      <div className="container">
        <Header margin={{ b: 20 }}>
          <HeaderLeft>
          </HeaderLeft>
          <HeaderCenter>
            <span className="bodyLarge">
              Confirmación
            </span>
          </HeaderCenter>
          <HeaderRight>
          </HeaderRight>
        </Header>
        <Box className="main">
          <TransactionWrapper>
            {step === 1 && (
              <>
                <ImageWrapper>
                  <img src={SuccessIcon} alt="Icono satisfactorio" />
                </ImageWrapper>
                <div>
                  <div className="headlineLarge" style={{ color: colors.deepblue, marginBottom: '10px' }}>
                    ¡Confirmación exitosa! {'\n'}
                  </div>
                  <div className="bodyLarge">
                    La tienda ha confirmado la orden.{'\n'}
                    {/* Toma una captura del anaquel para terminar el proceso.{'\n'} */}
                  </div>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <ImageThumbnail
                  uri={shelf_finish_image?.url}
                  name={shelf_finish_image?.name}
                  onPress={() => {
                    dispatch(setFinishImage(null))
                    setStep(1)
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    // Enviar foto de anaquel
                    setStep(3)
                    handleSendImage()
                  }}
                >
                  Enviar
                </Button>
              </>
            )}
            {step === 3 && (
              <>
                <ImageWrapper>
                  <img src={SuccessIcon} alt="Icono satisfactorio" />
                </ImageWrapper>
                <div>
                  <div className="headlineLarge" style={{ color: colors.deepblue }}>
                    Imagen recibida {'\n'}
                  </div>
                  <div className="bodyMedium">
                    Recibimos correctamente tu imagen {'\n'}
                  </div>
                </div>
              </>
            )}
            {step === 4 && (
              <>
                <CenterWrapper>
                  <div style={{ width: 100, height: 100, marginBottom: '20px' }}>
                    {store_logo ?
                      <img
                        style={{ width: '100%', height: '100%' }}
                        src={store_logo}
                        alt="Imágen de la tienda"
                      /> : 
                      <PlaceholderNPS />
                    }
                  </div>
                </CenterWrapper>
                <CenterWrapper>
                  <div className="bodyLarge" style={{ color: colors.deepblue }}>
                    Califica a {store_name}
                  </div>
                </CenterWrapper>
                <CenterWrapper>
                  <StarsRate onRateSelect={(value) => setRate(value)} width='80%' margin={{ b: 15, t: 15 }} />
                </CenterWrapper>
                <Input
                  name="comments"
                  control={control}
                  label="Agrega tu comentario"
                  fullWidth
                />
              </>
            )}
            {step === 5 && (
              <>
                <ImageWrapper>
                  <img src={SuccessIcon} alt="Icono satisfactorio" />
                </ImageWrapper>
                <div>
                  <div className="headlineLarge" style={{ color: colors.deepblue, marginBottom: '10px' }}>
                    Gracias por tus comentarios {'\n'}
                  </div>
                  <div className="bodyLarge">
                    Nos ayudan a mejorar los procesos.{'\n'}
                  </div>
                </div>
              </>
            )}
          </TransactionWrapper>
          <div style={styles.contentBottom}>
            {step === 1 && (
              <>
                <Button fullWidth onClick={() => setStep(4)} style={{ marginBottom: 10 }} variant="contained">
                  Continuar
                </Button>
                {/* <Button
                  variant="contained"
                  icon="camera"
                  onClick={() => {
                    navigation.navigate('CaptureImage', { type: CAMERA_TYPES.FINAL_IMAGE })
                  }}
                >
                  Tomar foto de Anquel
                </Button> */}
              </>
            )}
            {step === 3 && (
              <LoadingButton
                loadingPosition="start"
                variant="contained"
                onClick={() => {
                  // Aquí la función para subir la imágen
                  setStep(4)
                }}
                loading={rateIsLoading}
                disabled={rateIsLoading}
                fullWidth
              >
                Continuar
              </LoadingButton>
            )}
            {step === 4 && (
              <>
                <Button fullWidth variant="contained" onClick={handleSubmit(onSubmit)} loading={rateIsLoading} disabled={rateIsLoading}>
                  Continuar
                </Button>
                <Button fullWidth onClick={goHome} disabled={rateIsLoading}>
                  Cerrar
                </Button>
              </>
            )}
            {step === 5 && (
              <Button fullWidth onClick={goHome} variant="contained">
                Cerrar
              </Button>
            )}
          </div>
        </Box>
      </div>
    )
  }

  return (
    <div className="container">
      <Header margin={{ b: 20 }}>
        <HeaderLeft>
        </HeaderLeft>
        <HeaderCenter>
          <span className="bodyLarge">
            Confirmación
          </span>
        </HeaderCenter>
        <HeaderRight>
        </HeaderRight>
      </Header>
      <Box className="main">
        <TransactionWrapper>
          <ImageWrapper>
            <img src={ErrorIcon} alt="Icono error" />
          </ImageWrapper>
          <div>
            <div className="headlineLarge" style={{ color: colors.oriolesred, marginBottom: '10px' }}>
              Operación declinada por la tienda{'\n'}
            </div>
            <div className="bodyMedium">
              Por favor, revisa y repite el proceso en los productos y campos donde sea necesario.{'\n'}
            </div>
          </div>
        </TransactionWrapper>
        <div style={styles.contentBottom}>
          <Button variant="contained" onClick={backToDetails} fullWidth>
            Revisar surtido
          </Button>
          {/* <Button onClick={goHome}>
            Cerrar
          </Button> */}
        </div>
      </Box>
    </div>
  )
}

export default NPS