import { useController } from 'react-hook-form'
// import TextField from '@mui/material/TextField'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

import './styles.scss'

function Input({ style, rules, name, control, label, ...rest }) {
  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name,
    rules: rules || {},
    style: style || {},
  })

  const { message, type } = fieldState?.error || {}

  return (
    <div style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} >
      <FormControl variant="outlined" fullWidth={rest?.fullWidth} margin={rest?.margin}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <OutlinedInput
          value={String(field.value)}
          onChange={(ev) => field.onChange(ev.target.value)}
          sx={style}
          id={name}
          label={label}
          {...rest}
        />
      </FormControl>
      {fieldState.invalid && (
        <p className='field-error'>
          {type === 'required' ? 'Campo requerido' : message }
        </p>
      )}
    </div>
  )
}

export default Input