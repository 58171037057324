import request from '../utils/request'

export const getVendorInfo = async () => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/vendor/info`,
  })
  return response?.data
}

export const getVendorSummary = async ({ period }) => {
  const resposne = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/vendor/summary/${period}`
  })
  return resposne?.data;
}

export const getVendorPeriods = async () => {
  const resposne = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/vendor/periods`
  })
  return resposne?.data;
}

export const fixDiscrepancy = async (data) => {
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/vendor/discrepancy`,
    data,
  })
  return response?.data
}