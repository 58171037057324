import request from '../utils/request'

export const getStoresList = async () => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/vendor/stores`,
  })
  return response?.data
}

export const filterStores = async ({ inventory_state = '', zone = '', name = '' }) => {
  const zone_value = zone ? zone.join(',') : ''
  // console.log('filterStores', `${process.env.REACT_APP_API_URL}/vendor/filter_stores?inventory_state=${inventory_state}&zone=${zone_value}&name=${name}`)
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/vendor/filter_stores?inventory_state=${inventory_state}&zone=${zone_value}&name=${name}`,
  })
  return response?.data
}