import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom' 
import { Button, Box } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import {
  Header,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
} from '../../styles/shared'
import BackButton from '../../components/BackButton'
import styles from './styles'
import InventoryProduct from '../../components/InventoryProduct'
import {
  setStep,
  resetStep, 
  addRestockProduct, 
  // setProductImage,
  setProductRefilledImage
} from '../../store/slices/shelf'
import Step1 from '../../components/RestockSteps/Step1'
import Step2 from '../../components/RestockSteps/Step2'
// import Step3 from '../../components/RestockSteps/StepX'
import Step3 from '../../components/RestockSteps/Step3'
import Step4 from '../../components/RestockSteps/Step4'
import { fixDiscrepancy } from '../../services/vendor'
import WarningModal from './WarningModal'
import Modal from '../../components/Modal'
// import { CAMERA_TYPES } from '../../constants/types'
// import ImageThumbnail from '../../components/ImageThumbnail'
// import colors from '../../styles/colors'

function RestockScreen({ navigation }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { control, handleSubmit, watch, setValue, getValues } = useForm()
  const {
    step,
    shelf_product_image,
    shelf_product_refilled_image,
    selected_product: product,
    shelf_init_image,
  } = useSelector(state => state.shelf)
  const { id: store_id } = useSelector(state => state.store)
  const [showStockInput, setShowStockInput] = useState(false)
  const [showExpiredWasteInputs, setShowExpiredWasteInputs] = useState(false)
  const [discrepancyModalOpen, setDiscrepancyModalOpen] = useState(false)
  const [error, setError] = useState(null)

  const restock = product?.stock_level

  const watchStockMatch = watch('stock_match')
  const watchWaste = watch('waste')
  const watchExpired = watch('expired')
  const watchExisting = watch('existing')
  const watchExpiredWaste = watch('expired_waste')
  const watchRestockState = watch('restock_state')
  const watchCustomRestock = watch('custom_restock')
  const watchReason = watch('no_restock_reason')

  const enableRestockValue = (watchWaste || watchWaste === "0") && (watchExpired || watchExpired === "0") && (watchExisting || watchExisting === "0")

  // console.log({ restock, watchExisting })
  const expiredAndWaste = Number(watchWaste || 0) + Number(watchExpired || 0)
  const restockValue = Number(watchExisting) ? ((restock + (watchExpiredWaste === 'yes' && expiredAndWaste)) - Number(watchExisting)) : restock
  const showRestockError = enableRestockValue && (Number(watchWaste) + Number(watchExpired)) > Number(watchExisting) ? true : false

  const disableContinueBtn = () => {
    if (step == 1 && watchStockMatch) {
      // return false // && watchExisting && Number(watchExisting) <= restock
      if (watchStockMatch) {
        return false
      }
    } else if (step == 2 && watchExpiredWaste) {
      if (watchExpiredWaste && !showExpiredWasteInputs) {
        // false
        return false
      } else {
        if (enableRestockValue /*&& shelf_product_image*/ && !showRestockError) {
          return false
        } else {
          return true
        }
      }
    } else if (step == 3 && watchRestockState) {
      if (watchRestockState == 'yes') {
        return false
      } else if (watchRestockState != 'yes') {
        return false
      } else {
        return true
      }
    } else if (step == 4 && ((watchCustomRestock /* && shelf_product_refilled_image */ && watchCustomRestock <= restockValue) || watchReason)) {
      return false
    } else {
      return true
    }
  }

  const nextStep = ({ step, data }) => {
    dispatch(setStep({ step, restock_product: data }))
  }

  useEffect(() => {
    if (watchStockMatch === 'yes') {
      setValue('existing', `${product?.last_kwon_stock}`)
    } else if (watchStockMatch === 'no') {
      setValue('existing', '')
    }
  }, [watchStockMatch])

  const handleDiscrepancy = async () => {
    const data = getValues()
    try {
      await fixDiscrepancy({ sku: product.sku, store_id, quantity: Number(data?.existing) })
      nextStep({ step: 2, data })
    } catch (err) {
      setError('Error al corregir la discrepancia')
    }
    setDiscrepancyModalOpen(false)
  }

  const onSubmit = data => {
    const expired_waste = data?.expired_waste == 'yes' ? true : false
    const product_units = {
      sku: product.sku,
      restock_state: data?.restock_state,
      reason: data?.restock_state == 'no' ? data?.no_restock_reason : '',
      images: {
        shelf_init_image,
        shelf_product_image,
        shelf_product_refilled_image,
      },
      units: {
        waste: expired_waste ? Number(data?.waste) || 0 : 0,
        expired: expired_waste ? Number(data?.expired) || 0 : 0,
        existing: Number(data?.existing) || 0,
        supply: data?.restock_state == 'no' ? 0 : Number(data?.custom_restock) || restockValue,
      }
    }

    if (step == 1) {
      if (watchStockMatch === 'no') {
        setShowStockInput(true)
        if (data?.existing) {
          if (Number(data?.existing) > product?.last_kwon_stock) {
            setDiscrepancyModalOpen(true)
          } else {
            nextStep({ step: 2, data })
          }
        }
      } else if (watchStockMatch === 'yes') {
        setShowStockInput(false)
        nextStep({ step: 2, data })
      }
    } else if (step == 2) {
      if (watchExpiredWaste === 'yes') {
        setShowExpiredWasteInputs(true)
        if (data?.waste || data?.expired) {
          nextStep({ step: 3, data })
        }
      } else if (watchExpiredWaste === 'no') {
        setShowExpiredWasteInputs(false)
        nextStep({ step: 3, data })
      }
    } else if (step == 3) {
      if (watchRestockState == 'yes') {
        dispatch(addRestockProduct(product_units))
        navigate('/ConsignmentDetail', { replace: true })
      } else {
        nextStep({ step: 4, data })
      }
    } else if (step == 4) {
      dispatch(addRestockProduct(product_units))
      navigate('/ConsignmentDetail', { replace: true })
    }
  }

  const navigateBack = () => {
    if (step == 2) {
      setShowStockInput(false)
      setShowExpiredWasteInputs(false)
      setValue('waste', '')
      setValue('expired', '')
    }
    nextStep({ step: step - 1, data: {} })

    if (step <= 1) {
      dispatch(resetStep())
      navigate(-1)
    }
  }

  // const refilledProductPhoto = () => {
  //   if (!shelf_product_refilled_image)
  //     return (
  //       <Button
  //         icon="camera"
  //         onClick={() => navigate('CaptureImage', { type: CAMERA_TYPES.PRODUCT_REFILLED_IMAGE })}
  //         textColor={colors.deepblue}
  //         style={styles.cameraBtn}
  //       >
  //         Tomar foto de anaquel resurtido
  //       </Button>
  //     )
  //   else {
  //     return (
  //       <ImageThumbnail
  //         uri={shelf_product_refilled_image?.url}
  //         name={shelf_product_refilled_image?.name} 
  //         onPress={() => dispatch(setProductRefilledImage(null))}
  //       />
  //     )
  //   }
  // }

  return (
    <div className="container">
      <Modal onClose={() => setError(null)} isOpen={!!error} type='error' message={error} />
      <Header padding={{ b: 46 }}>
        <HeaderLeft>
          <BackButton onPress={() => navigateBack()} />
        </HeaderLeft>
        <HeaderCenter>
          <span className="bodyLarge">
            Resurtido
          </span>
        </HeaderCenter>
        <HeaderRight>
        </HeaderRight>
      </Header>
      <Box className="main">
        <InventoryProduct
          hideRadio
          radius={16}
          hideImage
          margin={{ b: 16 }}
          product={product}
        />
        {step == 1 && (
          <Step1 control={control} setValue={setValue} watch={watch} last_kwon_stock={product?.last_kwon_stock} showStockInput={showStockInput} />
        )}
        {step == 2 && (
          <Step2 control={control} setValue={setValue} watch={watch} getValues={getValues} showExpiredWasteInputs={showExpiredWasteInputs} navigation={navigation} />
        )}
        {step == 3 && (
          <>
            <Step3
              control={control} 
              setValue={setValue} 
              showRestockError={showRestockError} 
              restockValue={restockValue}
            />
            {/* {watchRestockState == 'yes' && refilledProductPhoto()} */}
          </>
        )}
        {step == 4 && watchRestockState !== 'yes' && (
          <>
            <Step4 control={control} setValue={setValue} showReason={watchRestockState == 'no'} />
            {/* {watchRestockState == 'not-enough' && refilledProductPhoto()} */}
          </>
        )}

        {showRestockError && (
          <p className="field-error">
            El valor de merma y expirado no puede ser mayor al valor existente
          </p>
        )}
        {enableRestockValue && Math.abs(restockValue) > restock && restockValue > 0 && (
          <p className="field-error">
            No se pueden surtir más productos de los indicados
          </p>
        )}
        
        <Button
          variant="contained" 
          style={styles.primaryBtn} 
          disabled={disableContinueBtn()} 
          onClick={handleSubmit(onSubmit)}
          fullWidth
        >
          Continuar
        </Button>
      </Box>
      <Dialog
        open={discrepancyModalOpen}
        onClose={() => setDiscrepancyModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <WarningModal onConfirm={handleDiscrepancy} onDismiss={() => setDiscrepancyModalOpen(false)} existing={watchExisting} />
      </Dialog>
    </div>
  )
}

export default RestockScreen
