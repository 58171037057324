import styled from 'styled-components'

import { COLORS } from '../styles/colors'


export const Header = styled.div`
  display: flex;
  padding-bottom: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.margin?.b || 0}px;
  margin-top: ${props => props.margin?.t || 0}px;
  margin-left: ${props => props.margin?.l || 0}px;
  margin-right: ${props => props.margin?.r || 0}px;
  
  padding-bottom: ${props => props.padding?.b || 0}px;
  padding-top: ${props => props.padding?.t || 20}px;
  padding-left: ${props => props.padding?.l || 0}px;
  padding-right: ${props => props.padding?.r || 0}px;
`

export const HeaderCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

export const HeaderLeft = styled.div`
  width: 40px;
`

export const HeaderRight = styled.div`
  width: 40px;
  align-items: flex-end;
`


export const Divider = styled.div`
  border-bottom-style: solid;
  border-bottom-width: ${props => props?.borderWidth || 1}px;
  border-bottom-color: ${props => props?.color || COLORS.darkgrey};
  width: 100%;
  margin-top: ${props => props?.margin?.t || 0}px;
  margin-bottom: ${props => props?.margin?.b || 0}px;
`

export const StatusCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: ${props => props.color || COLORS.lightgrey};
  /* margin-right: 4px; */
`

export const DataWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.margin?.b || 0}px;
  margin-top: ${props => props.margin?.t || 0}px;
  margin-left: ${props => props.margin?.l || 0}px;
  margin-right: ${props => props.margin?.r || 0}px;
  
  padding-bottom: ${props => props.padding?.b || 0}px;
  padding-top: ${props => props.padding?.t || 0}px;
  padding-left: ${props => props.padding?.l || 0}px;
  padding-right: ${props => props.padding?.r || 0}px;
`


export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
`

export const Column = styled.div`
  flex-direction: row;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex: 1;
`

export const ColLeft = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
`

export const ColRight = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: center;
  align-items: flex-end;
  min-width: 65px;
`


const styles = {
  link: {
    color: COLORS.deepblue,
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline'
  },
  contentBottom: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingTop: 16
  },
  bold: {
    fontWeight: 'bold'
  },
  centerContent: {
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  }
}

export default styles