import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'

import {
  Header,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
  Row,
  Column,
} from 'styles/shared'
import BackButton from 'components/BackButton'
import styles, { TotalWrapper } from './styles'
import InventoryProduct from '../../components/InventoryProduct'
import Input from '../../components/Input'
import colors from '../../styles/colors'
import {
  addRestockProduct,
  // setRecallImage,
} from '../../store/slices/shelf'
// import ImageThumbnail from '../../components/ImageThumbnail'
// import { CAMERA_TYPES } from '../../constants/types'


function Recall({ navigation }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { control, handleSubmit, watch } = useForm()
  const {
    shelf_init_image,
    shelf_product_image,
    shelf_recall_image,
    selected_product: product
  } = useSelector(state => state.shelf)

  const watchExisting = watch('existing')

  const onSubmit = data => {
    const product_units = {
      sku: product.sku,
      restock_state: data?.restock_state,
      images: {
        shelf_init_image,
        shelf_product_image,
        shelf_recall_image,
      },
      units: {
        waste: Number(data?.waste) || 0,
        expired: Number(data?.expired) || 0,
        existing: Number(data?.existing) || 0,
        stock_removal: Number(data?.existing),
      }
    }
    dispatch(addRestockProduct(product_units))
    navigate('/ConsignmentDetail')
  }

  const disableContinueBtn = () => {
    if (watchExisting) {
      return false
    } else {
      return true
    }
  }

  // const refilledProductPhoto = () => {
  //   if (!shelf_recall_image)
  //     return (
  //       <Button
  //         icon="camera"
  //         onPress={() => navigation.navigate('CaptureImage', { type: CAMERA_TYPES.PRODUCT_RECALLED_IMAGE })}
  //         textColor={colors.deepblue}
  //         style={styles.cameraBtn}
  //       >
  //         Toma foto de los productos a retirar
  //       </Button>
  //     )
  //   else {
  //     return (
  //       <ImageThumbnail
  //         uri={shelf_recall_image?.url}
  //         name={shelf_recall_image.name} 
  //         onPress={() => dispatch(setRecallImage(null))}
  //       />
  //     )
  //   }
  // }

  return (
    <div className="container">
      <Header padding={{ b: 46 }}>
        <HeaderLeft>
          <BackButton onPress={() => navigation.goBack()} />
        </HeaderLeft>
        <HeaderCenter>
          <span className="bodyLarge" style={{ color: colors.oriolesred }}>
            Retiro
          </span>
        </HeaderCenter>
        <HeaderRight>
        </HeaderRight>
      </Header>
      <Box>
        <InventoryProduct
          hideRadio
          radius={16}
          hideImage
          margin={{ b: 16 }}
          product={product}
        />
        <div className="bodyLarge" style={{ color: colors.darkgrey }}>
          {'\n'}Cuenta el producto en anaquel y llena:
        </div>
        <Input
          name="existing"
          control={control}
          label="Total de piezas existentes"
          rules={{
            required: true,
            validate: {
              numbers: (value) => Number.isInteger(Number(value)) || 'Debe contener solo números enteros',
            }
          }}
          fullWidth
        />
        <Input
          name="waste"
          control={control}
          label="Piezas merma"
          rules={{
            validate: {
              numbers: (value) => Number.isInteger(Number(value)) || 'Debe contener solo números enteros',
            }
          }}
          fullWidth
        />
        <Input
          name="expired"
          control={control}
          label="Piezas Expiradas"
          rules={{
            validate: {
              numbers: (value) => Number.isInteger(Number(value)) || 'Debe contener solo números enteros',
            }
          }}
          fullWidth
        />
        <Row>
          <Column>
            <span className="bodyLarge" style={{ fontWeight: 'bold', color: colors.oriolesred }}>
              Total a retirar:
            </span>
          </Column>
          <Column style={{ flex: 1 }}>
            <TotalWrapper>
              <span className="bodyLarge" style={{ fontWeight: 'bold', color: colors.oriolesred }}>
                {watchExisting}
              </span>
            </TotalWrapper>
          </Column>
        </Row>
        {/* {refilledProductPhoto()} */}
        <Box mt={2}>
          <Button variant="contained" style={styles.primaryBtn} disabled={disableContinueBtn()} onClick={handleSubmit(onSubmit)} fullWidth>
            Continuar
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default Recall
