import styled from 'styled-components'
import { COLORS } from '../../styles/colors'

export const StatusBar = styled.div`
  background-color: ${props => props.color};
  padding-top: 8px;
  padding-bottom: 8px;
`

export const RateWrapper = styled.div`
  flex-direction: row;
`

export const PhoneWrapper = styled.div`
  flex-direction: row;
  align-items: center;
`

const styles = {
  scrollView: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 24,
    paddingBottom: 24,
    flex: 1,
    backgroundColor: COLORS.lightgrey,
    minHeight: '100%',
  },
  body: {
    flexGrow: 1,
    paddingLeft: 16,
    paddingRight: 16,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textSpace: {
    marginTop: 4,
    marginBottom: 4,
  },
  image: {
    width: '100%',
    height: 200,
  },
  primaryBtn: {
    marginBottom: 10
  }
}

export default styles
