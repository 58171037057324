import { Close } from '@mui/icons-material'

import colors from '../../styles/colors'
import styles, { Container, NameWrapper } from './styles'

function ImageThumbnail({ uri, name, onPress }) {
  return (
    <Container>
      <img
        src={uri}
        style={styles.image}
        // resizeMode="cover"
        alt="Foto de anaquel"
      />
      <NameWrapper>
        <span style={{ color: colors.darkgrey }}>{name}</span>
      </NameWrapper>
      <Close fontSize='small' onClick={onPress} />
    </Container>
  )
}

export default ImageThumbnail