import './styles.scss'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Box from '@mui/material/Box'

function Modal({ children, onClose, isOpen, type, message }) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Box minWidth={300}>
          <div className='title'>
            {type === 'error' ? <div className='error'><ErrorOutlineIcon />Error:</div> : ''}
          </div>
          {message &&
            <div>
              {message}
            </div>
          }
          {children}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button className='btn-main-simple' onClick={onClose} type="submit" variant="contained">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Modal