import { COLORS } from 'styles/colors'
import styled from 'styled-components'

export const TotalWrapper = styled.div`
  display: inline-block;
  text-align: center;
  background-color: ${props => props.color || COLORS.white};
  border-radius: 8px;
  padding: 8px;
  width: 100%;
`

const styles = {
  colLeft: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  cameraBtn: {
    marginTop: 16,
    marginBottom: 16,
  },
}

export default styles