import { useState } from 'react'
import { StarsWrapper } from './styles'
import Star from 'assets/Star'
import colors from 'styles/colors'

function StarsRate({ maxRate = 5, onRateSelect, width, margin }) {
  const [rate, setRate] = useState(0)
  let stars = [...Array(maxRate).keys()]

  const handleRateSelect = (value) => {
    setRate(value)
    onRateSelect?.(value)
  }

  return (
    <StarsWrapper width={width} margin={margin}>
      {stars.map(item => (
        <div key={item} onClick={() => handleRateSelect(item + 1)}>
          <Star color={rate <= item ? colors.midgrey : colors.hanpurple} />
        </div>
      ))}
    </StarsWrapper>
  )
}

export default StarsRate