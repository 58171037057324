import { useSelector } from 'react-redux'
import Radio from '@mui/material/Radio'

import styles, { ProductWrapper } from './styles'
import sharedStyles, { Row, ColLeft, ColRight } from '../../styles/shared'
import colors from '../../styles/colors'
import PlaceholderImage from '../../assets/img/PlaceholderImage.svg'

const InventoryProduct = ({ product, radius, margin, hideImage, hideRadio, onPress }) => {
  const { products_restock } = useSelector(state => state.shelf)
  const { short_name, stock_level, tray, position, sku, restock, last_kwon_stock } = product
  const current_product = products_restock?.[product.sku]
  const color = restock == false ? colors.oriolesred : colors.primary
  
  return (
    <div onClick={onPress}>
      <ProductWrapper radius={radius} margin={margin}>
        {!hideImage && (
          <img style={styles.image} src={PlaceholderImage} alt="Foto del producto" />
        )}
        <div style={{ flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '5px' }}>
            {!hideRadio && 
              <div style={{ marginLeft: -10, display: 'inline-block' }}>
                <Radio
                  checked={current_product?.units ? true : false}
                  onChange={onPress}
                  value={sku}
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'A' }}
                />
              </div>
            }
            <div style={{ flex: 1 }}>
              <div className="bodyLarge" style={{ color }}>
                {short_name}
              </div>
            </div>
          </div>
          <div>
            {!hideImage ? 
              <>
                <Row>
                  <ColLeft>
                    <span className="bodyMedium">Último conteo:</span>
                  </ColLeft>
                  <ColRight>
                    <span className="bodyMedium">{last_kwon_stock}</span>
                  </ColRight>
                </Row>
              </> : <>
                <Row>
                  <ColLeft>
                    <span className="bodyMedium">Nivel en anaquel:</span>
                  </ColLeft>
                  <ColRight>
                    <span className="bodyMedium" style={sharedStyles.bold}>{tray}</span>
                  </ColRight>
                </Row>
                <Row>
                  <ColLeft>
                    <span className="bodyMedium">Posición en charola:</span>
                  </ColLeft>
                  <ColRight>
                    <span className="bodyMedium" style={sharedStyles.bold}>{position}</span>
                  </ColRight>
                </Row>
                <Row>
                  <ColLeft>
                    <span className="bodyMedium">Capacidad total:</span>
                  </ColLeft>
                  <ColRight>
                    <span className="bodyMedium" style={sharedStyles.bold}>{stock_level}</span>
                  </ColRight>
                </Row>
              </>
            }
          </div>
        </div>
      </ProductWrapper>
    </div>
  )
}

export default InventoryProduct
