import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { PersonOutline, PhoneInTalk } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import sharedStyles, {
  Header,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
  Divider,
  DataWrapper
} from '../../styles/shared'
import BackButton from '../../components/BackButton'
import styles, { StatusBar, RateWrapper, PhoneWrapper } from './styles'
import statusInfo from '../../utils/status'
import { clearReceipt, clearShelfState } from '../../store/slices/shelf'
import { clearStoreState } from '../../store/slices/store'
import colors from '../../styles/colors'

const StoreInfo = ({ route }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const store = useSelector((state) => state.store)
  // const { shelf_number } = useSelector((state) => state.shelf)
  const { state, municipality, neighborhood, street, ext_number, reference, geo } = store?.address || {}
  const shelf_number = route?.params?.shelf_number
  const [statusStyle] = useState(statusInfo(store?.inventory_status))
  const [storeImage] = useState(store?.images?.[0]?.front_view)

  const goConsignmentDetail = () => {
    dispatch(clearReceipt())
    navigate('/ConsignmentDetail')
  }

  const onBack = () => {
    dispatch(clearStoreState())
    dispatch(clearShelfState())
    navigate(-1)
  }
  
  const openMaps = async () => {
    try {
      const encodedLabel = encodeURIComponent(store?.name)
      const url = `geo:${geo?.lat},${geo?.lng}?q=${geo?.lat},${geo?.lng}(${encodedLabel})&z=15`
      window.location.href = url
      // await Linking.openURL(url)
    } catch (error) {
      alert('Error al abrir Google Maps:', error)
      // Handle the error as needed (e.g., show an error message to the user)
    }
  }

  return (
    <div className="container">
      <Header padding={{ l: 16, r: 16, b: 16 }}>
        <HeaderLeft>
          <BackButton onPress={onBack} />
        </HeaderLeft>
        <HeaderCenter>
          <div className="bodyLarge">
            Detalle de Tienda
          </div>
        </HeaderCenter>
        <HeaderRight>
        </HeaderRight>
      </Header>
      <div>
        <img src={storeImage} style={styles.image} alt="Fotografia de la tienda" />
        {!shelf_number && (
          <StatusBar color={statusStyle.color}>
            <div
              className="bodyLarge"
              style={{ ...sharedStyles.bold, color: statusStyle.textColor, textAlign: 'center' }}
            >
              {statusStyle.label}
            </div>
          </StatusBar>
        )}
      </div>
      <Box className="main" pb={4}>
        {!shelf_number && (
          <>
            <DataWrapper margin={{ t: 24 }}>
              <Button variant="contained" onClick={openMaps} fullWidth>
                Abrir en Google Maps
              </Button>
            </DataWrapper>
            <DataWrapper margin={{ t: 16 }}>
              <Button variant="outlined" onClick={goConsignmentDetail} fullWidth>
                Iniciar proceso de consignación
              </Button>
            </DataWrapper>
          </>
        )}
        <Box my={2} className="headlineSmall" sx={{ color: colors.deepblue }}>
          {store?.name}
        </Box>
        <Box mb={2} className="bodyMedium" sx={{ fontWeight: '600' }}>
          {`${street} ${ext_number}, ${neighborhood}, ${municipality}, ${state}`}
        </Box>
        <div className="bodySmall">
          Referencias: {reference} {'\n'}
        </div>
        {!shelf_number && (
          <>
            {/* <RateWrapper>
              <Icon name="star" size={20} />
              <Text>{store?.rate}</Text>
            </RateWrapper> */}
            <Divider margin={{ t: 20, b: 20 }} color={colors.midgrey} />
          </>
        )}
        <Box className="bodyMedium" mb={1}>
          <PersonOutline fontSize="small" />
          Responsable: <span style={{ fontWeight: '600' }}>{store?.owner}</span>
        </Box>
        <Box className="bodyMedium">
          <PhoneInTalk fontSize="small" />
          Teléfono: <a href={`tel:${store?.phone}`}>{store?.phone}</a>
        </Box>
        {shelf_number && (
          <>
            <DataWrapper margin={{ t: 16, b: 16 }}>
              <span variant="bodyLarge">
                ¿Estás en esta tienda?
              </span>
            </DataWrapper>
            <Button
              variant="contained"
              style={styles.primaryBtn}
              onPress={goConsignmentDetail}
            >
              Sí
            </Button>
            <Button
              onPress={onBack}
            >
              No
            </Button>
          </>
        )}
      </Box>
    </div>
  )
}

export default StoreInfo
