import Box from '@mui/material/Box'

import sharedStyles, {
  Row,
  Column,
} from '../../styles/shared'
import styles, { TotalWrapper } from './styles'
import colors from '../../styles/colors'
import RadioGroup from 'components/RadioGroup'

function Step3({ control, setValue, showRestockError, restockValue }) {

  return (
    <div>
      <Box className="headlineSmall" mb={2}>
        Deja en anaquel:
      </Box>
      <Row>
        <Column>
          <TotalWrapper color={(!showRestockError && restockValue > 0) ? colors.success : colors.midgrey}>
            <span className="bodyLarge" style={{ fontWeight: 'bold', color: colors.white }}>
              {restockValue}
            </span>
          </TotalWrapper>
        </Column>
        <Column style={styles.colLeft}>
          <span className="bodyLarge" style={sharedStyles.bold}>
            Unidades
          </span>
        </Column>
      </Row>
      <Box className="headlineSmall" mt={2} mb={2}>
        ¿Puedes surtir los productos?
      </Box>
      <RadioGroup
        control={control}
        name="restock_state"
        label=""
        rules={{ required: true }}
        options={[
          { label: 'Si', value: 'yes' },
          { label: 'Si, pero incompleto', value: 'not-enough' },
          { label: 'No', value: 'no' },
        ]}
      />
    </div>
  )
}

export default Step3