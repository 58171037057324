import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

import Input from '../../components/Input'
import RadioGroup from '../../components/RadioGroup'
import styles, { TotalWrapper } from './styles'
import sharedStyles, {
  Row,
  Column,
} from '../../styles/shared'
import colors from '../../styles/colors'

function Step1({ control, setValue, watch, last_kwon_stock, showStockInput }) {
  const watchExisting = watch('existing')

  return (
    <div>
      {!showStockInput &&
        <>
          <Box className="headlineSmall" mb={2}>
            Existencias del último conteo:
          </Box>
          <Row>
            <Column>
              <TotalWrapper color={colors.black}>
                <span className="bodyLarge" style={{...sharedStyles.bold, color: colors.white }}>
                  {last_kwon_stock}
                </span>
              </TotalWrapper>
            </Column>
            <Column style={styles.colLeft}>
              <span className="bodyLarge" style={sharedStyles.bold}>
                Unidades
              </span>
            </Column>
          </Row>
          <Box className="headlineSmall" mt={2}>
            ¿Hay <span style={sharedStyles.bold}>{last_kwon_stock}</span> en la charola?
          </Box>
          <RadioGroup
            control={control}
            name="stock_match"
            label=""
            rules={{ required: true }}
            options={[
              { label: 'Si', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
          />
        </>
      }
      {showStockInput && 
        <>
          <div className="headlineSmall">¿Cuántas piezas hay en anaquel?</div>
          <Input
            name="existing"
            control={control}
            label="Piezas existentes en anaquel"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="borrar valor"
                  onClick={() => setValue('existing', '')}
                  edge="end"
                >
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            }
            rules={{
              required: true,
              validate: {
                numbers: (value) => Number.isInteger(Number(value)) || 'Debe contener solo números enteros',
                positive: (value) => Number(value) >= 0 || 'Debe ser un número positivo'
              }
            }}
          />
          {Number(watchExisting) > last_kwon_stock && (
            <div className="bodyLarge" style={{ color: colors.oriolesred, fontWeight: 'bold' }}>
              <p>El número de unidades por registrar es mayor al que tenemos registrado.</p>
              <p>
                <span style={{ fontWeight: 'normal'}}>Si continúas</span> esta acción modificará tus ventas y existencias. {' '}
              </p>
            </div>
          )}
        </>
      }
    </div>
  )
}

export default Step1
