import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: 'space-between';
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const NameWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
`

const styles = {
  image: {
    width: 50,
    height: 50,
    borderRadius: 8,
  }
}

export default styles