import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getVendorInfo } from '../../services/vendor'

export const getVendor = createAsyncThunk(
  'vendor/getVendor',
  async (_, { getState }) => {
    const { token } = getState().identity
    const response = await getVendorInfo({ token })
    return response
  }
)

const vendorSlice = createSlice({
  name: 'vendor',
  initialState: {
    user_id: '',
    vendor_id: '',
    user_name: '',
    vendor_name: '',
    vendor_type: '',
    users: [],
    preferences: {
      notification_paths: []
    },

    navigateHome: false,
    // navigateNPS: false,

    isLoading: false,
    error: null
  },
  reducers: {
    setNavigateHome: (state) => {
      state.navigateHome = true
    },
    // setNavigateNPS: (state) => {
    //   console.log('setNavigateNPS')
    //   state.navigateNPS = true
    // },
    clearNavigations: (state) => {
      state.navigateHome = false
      // state.navigateNPS = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getVendor.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getVendor.fulfilled, (state, { payload }) => {
      console.log('getVendor.fulfilled')
      state.user_id = payload?.user_id
      state.preferences = payload?.preferences
      state.vendor_id = payload?.vendor_id
      state.user_name = payload?.user_name
      state.vendor_name = payload?.vendor_name
      state.users = payload?.users
      state.vendor_type = payload?.vendor_type
      state.isLoading = false
      state.error = null
    })
    builder.addCase(getVendor.rejected, (state, action) => {
      console.log('getVendor.rejected', action.error.message)
      state.isLoading = false
      state.error = action.error.message
    })
  }
})

export const {
  setNavigateHome,
  // setNavigateNPS,
  clearNavigations
} = vendorSlice.actions
export default vendorSlice.reducer
