import styled from 'styled-components'

export const StarsWrapper = styled.div`
  display: flex;
  width: ${props => props.width || '100%'};
  min-width: 180px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${props => props.margin.t || 0}px;
  margin-right: ${props => props.margin.r || 0}px;
  margin-bottom: ${props => props.margin.b || 0}px;
  margin-left: ${props => props.margin.l || 0}px;
`