import COLORS from 'styles/colors'
// import styled from 'styled-components/native'

// export const ReceiptItemWrapper = styled.View`
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   align-items: center;
//   padding-top: 4px;
//   padding-bottom: 4px;
// `

// export const ColumnLeft = styled.View`
//   text-align: left;
//   justify-content: center;
//   align-items: flex-start;
//   flex: 1;
// `

// export const ColumnRight = styled.View`
//   text-align: right;
//   justify-content: center;
//   align-items: flex-end;
//   min-width: 65px;
// `


const styles = {
  scrollView: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 24,
    flexGrow: 1,
    backgroundColor: COLORS.lightgrey,
  },
  card: {
    flex: 1,
    borderRadius: 16,
    backgroundColor: 'white',
    minHeight: 300,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    paddingTop: 24
  },
}

export default styles