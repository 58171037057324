import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

import ButtonCard from '../../components/ButtonCard'
import { Divider } from '../../styles/shared'
import StoreCard from '../StoreCard'
import { useSelector, useDispatch } from 'react-redux'
import { getStores } from '../../store/slices/stores'
import { setStore } from '../../store/slices/store'
import colors from '../../styles/colors'

function StoreList({ navigation, stores }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isLoading } = useSelector(state => state.stores)

  const onRefresh = () => {
    dispatch(getStores())
  }

  const handleClick = (store) => {
    dispatch(setStore(store))
    navigate('/StoreInfo', { store })
  }

  return (
    <div>
      {stores?.map((store) => (
        <div key={store.id}>
          <ButtonCard padding={{ l: 0, t: 0, b: 0 }} onPress={() => handleClick(store)}>
            <StoreCard store={store} />
          </ButtonCard>
          <Divider color={colors?.midgrey} />
        </div>
      ))}
    </div>
  )
}

export default StoreList
