import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { CircularProgress, Box, IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { LoadingButton } from '@mui/lab'

import sharedStyles, {
  Header,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
  Divider,
  Row,
  ColLeft,
  ColRight
} from 'styles/shared'
import BackButton from 'components/BackButton'
import styles from './styles'
// import { toCurrency } from '../../utils/formats'
import { resetReceipt } from 'store/slices/shelf'
import { clearNavigations } from 'store/slices/vendor'
import { MOCKED_USER } from 'constants/info'
import WarningModal from './WarningModal'
import colors from 'styles/colors'

function ShelfReceipt() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [wasteList, setWasteList] = useState([])
  const [recognizedSalesList, setRecognizedSalesList] = useState([])
  const [restockList, setRestockList] = useState([])
  const [recallList, setRecallList] = useState([])
  const { user_name } = useSelector(state => state.vendor)
  const [open, setOpen] = useState(false)

  const {
    receipt,
    receiptIsLoading,
    settleIsLoading,
    // settleError,
    // settleStatus
  } = useSelector(state => state.shelf)

  const {
    // shelf_number,
    // id,
    created_date,
    order_details,
    store_name,
    // estimated_profit,
    // to_pay,
    // total_cost,
    // status,
    // receipt_user
  } = receipt

  const mapTable = () => {
    let waste_list = []
    let recognized_sales_list = []
    let restock_list = []
    let recall_list = []
    
    order_details.forEach(item => {
      if (item.units.waste || item.units.expired) {
        waste_list = [
          ...waste_list,
          {
            name: item.product_name,
            quantity: item.units.waste + item.units.expired
          }
        ]
      }
      if (item.units.recognized_sales) {
        recognized_sales_list = [
          ...recognized_sales_list,
          {
            name: item.product_name,
            quantity: item.units.recognized_sales
          }
        ]
      }
      if (item.units.supply) { // item.units.restock
        restock_list = [
          ...restock_list,
          {
            name: item.product_name,
            quantity: item.units.supply // item.units.restock
          }
        ]
      }
      if (item.units.stock_removal) {
        recall_list = [
          ...recall_list,
          {
            name: item.product_name,
            quantity: item.units.stock_removal
          }
        ]
      }
    })
    
    setWasteList(waste_list)
    setRecognizedSalesList(recognized_sales_list)
    setRestockList(restock_list)
    setRecallList(recall_list)
  }

  useEffect(() => {
    if (order_details?.length > 0 && receiptIsLoading === false) {
      mapTable()
    }
  }, [receiptIsLoading])

  const handleQRCodeWarning = () => {
    setOpen(true)
  }

  const handleQRCode = () => {
    setOpen(false)
    dispatch(clearNavigations())
    navigate('/ShelfReceiptQR')
  }

  const handleMock = () => {
    dispatch(clearNavigations())
    navigate('/NPS')
  }

  const formattedDate = DateTime.fromISO(created_date).toLocaleString(DateTime.DATE_MED)

  const isMocked = user_name === MOCKED_USER
  
  return (
    <div className="container">
      <div>
        <Header margin={{ b: 20 }}>
          <HeaderLeft>
            <BackButton onPress={() => {
              dispatch(resetReceipt())
              navigate(-1)}
            } />
          </HeaderLeft>
          <HeaderCenter>
            <span className="bodyLarge">
              Resumen
            </span>
          </HeaderCenter>
          <HeaderRight>
          </HeaderRight>
        </Header>
        <Box className="main">
          <div style={styles.card}>
            <Box mb={2}>
              {/* <Text className="titleLarge">
                {shelf_number}
              </Text> */}
              <Box className="bodyLarge" style={{ color: colors.deepblue }} mb={1}>
                {store_name}
              </Box>
              {!receiptIsLoading &&
                <div className="bodySmall" style={{ color: colors.darkgray }}>
                  {formattedDate}
                </div>
              }
            </Box>
            {receiptIsLoading &&
              <Box sx={{ display: 'flex', width: '100%', height: '300px', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            }

            {recallList?.length > 0 && (
              <span>
                <Row>
                  <ColLeft>
                    <span className="bodyLarge" style={{ color: colors.oriolesred }}>
                      Producto retirado
                    </span>
                  </ColLeft>
                  <ColRight>
                    <Tooltip title="Los productos expirados o merma no te cuentan como venta y no afectan tu monto por pagar">
                      <IconButton>
                        <HelpOutlineIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  </ColRight>
                </Row>
                <Row>
                  <ColLeft>
                    <span style={sharedStyles.bold}>
                      Producto
                    </span>
                  </ColLeft>
                  <ColRight>
                    <span style={sharedStyles.bold}>
                      Unidades
                    </span>
                  </ColRight>
                </Row>
                {recallList.map((item, index) => (
                  <Row key={`${item.sku}_${index}`}>
                    <ColLeft>
                      <span>{item.name}</span>
                    </ColLeft>
                    <ColRight>
                      <span>{item.quantity}</span>
                    </ColRight>
                  </Row>
                ))}
                <Divider color={colors.midgrey} />
                <Row>
                  <ColLeft>
                    <span>Elementos totales</span>
                  </ColLeft>
                  <ColRight>
                    <span>
                      {recallList.reduce((acc, item) => acc + item.quantity, 0)}
                    </span>
                  </ColRight>
                </Row>

                <Divider margin={{ t: 14, b: 14 }} color={colors.midnight} />
              </span>
            )}

            {wasteList?.length > 0 && (
              <span>
                <Row>
                  <ColLeft>
                    <span className="bodyLarge" style={{ color: colors.oriolesred }}>
                      Producto expirado o merma
                    </span>
                  </ColLeft>
                  <ColRight>
                    <Tooltip title="Los productos expirados o merma no te cuentan como venta y no afectan tu monto por pagar">
                      <IconButton>
                        <HelpOutlineIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  </ColRight>
                </Row>
                <Row>
                  <ColLeft>
                    <span style={sharedStyles.bold}>
                      Producto
                    </span>
                  </ColLeft>
                  <ColRight>
                    <span style={sharedStyles.bold}>
                      Unidades
                    </span>
                  </ColRight>
                </Row>
                {wasteList.map((item, index) => (
                  <Row key={`${item.sku}_${index}`}>
                    <ColLeft>
                      <span>{item.name}</span>
                    </ColLeft>
                    <ColRight>
                      <span>{item.quantity}</span>
                    </ColRight>
                  </Row>
                ))}
                <Divider color={colors.midgrey} />
                <Row>
                  <ColLeft>
                    <span>Elementos totales</span>
                  </ColLeft>
                  <ColRight>
                    <span>
                      {wasteList.reduce((acc, item) => acc + item.quantity, 0)}
                    </span>
                  </ColRight>
                </Row>

                <Divider margin={{ t: 14, b: 14 }} color={colors.midnight} />
              </span>
            )}

            
            {recognizedSalesList?.length > 0 && (
              <div>
                <Row>
                  <ColLeft>
                    <span className="bodyLarge" style={{ color: colors.success }}>
                      Producto vendido
                    </span>
                  </ColLeft>
                  <ColRight></ColRight>
                </Row>
                <Row>
                  <ColLeft>
                    <span style={sharedStyles.bold}>
                      Producto
                    </span>
                  </ColLeft>
                  <ColRight>
                    <span style={sharedStyles.bold}>
                      Unidades
                    </span>
                  </ColRight>
                </Row>
                {recognizedSalesList.map((item, index) => (
                  <Row key={`${item.sku}_${index}`}>
                    <ColLeft>
                      <span>{item.name}</span>
                    </ColLeft>
                    <ColRight>
                      <span>{item.quantity}</span>
                    </ColRight>
                  </Row>
                ))}
                <Divider color={colors.midgrey} />
                <Row>
                  <ColLeft>
                    <span>Elementos totales</span>
                  </ColLeft>
                  <ColRight>
                    <span>
                      {recognizedSalesList.reduce((acc, item) => acc + item.quantity, 0)}
                    </span>
                  </ColRight>
                </Row>
                {/* <Divider color={colors.midgrey} /> */}
                {/* <Row>
                  <ColLeft>
                    <Text style={[sharedStyles.bold, { color: colors.deepblue }]}>
                      Por Pagar
                    </Text>
                  </ColLeft>
                  <ColRight>
                    <Text>{toCurrency(to_pay)}</Text>
                  </ColRight>
                </Row>
                <Divider color={colors.midgrey} />
                <Row>
                  <ColLeft>
                    <Text style={[sharedStyles.bold, { color: colors.success }]}>
                      Ganancia estimada:
                    </Text>
                  </ColLeft>
                  <ColRight>
                    <Text>{toCurrency(estimated_profit)}</Text>
                  </ColRight>
                </Row> */}

                <Divider margin={{ t: 14, b: 14 }} color={colors.midnight} />
              </div>
            )}
            
            {restockList?.length > 0 && (
              <div>
                <Row>
                  <ColLeft>
                    <span className="bodyLarge" style={{ color: colors.hanpurple }}>
                      Producto consignado
                    </span>
                  </ColLeft>
                  <ColRight></ColRight>
                </Row>
                <Row>
                  <ColLeft>
                    <span style={sharedStyles.bold}>
                      Producto
                    </span>
                  </ColLeft>
                  <ColRight>
                    <span style={sharedStyles.bold}>
                      Unidades
                    </span>
                  </ColRight>
                </Row>
                {restockList.map((item, index) => (
                  <Row key={`${item.sku}_${index}`}>
                    <ColLeft>
                      <span>{item.name}</span>
                    </ColLeft>
                    <ColRight>
                      <span>{item.quantity}</span>
                    </ColRight>
                  </Row>
                ))}
                <Divider color={colors.midgrey} />
                <Row>
                  <ColLeft>
                    <span>Elementos totales</span>
                  </ColLeft>
                  <ColRight>
                    <span>
                      {restockList.reduce((acc, item) => acc + item.quantity, 0)}
                    </span>
                  </ColRight>
                </Row>
                <Divider margin={{ t: 14, b: 10 }} color={colors.midnight} />
              </div>
            )}

            <Box mt={3}>
              {!isMocked &&
                <LoadingButton fullWidth variant="contained" onClick={handleQRCodeWarning} loading={settleIsLoading} disabled={settleIsLoading} loadingPosition="start">
                  Generar QR Code
                </LoadingButton>
              }
              {isMocked && 
                <LoadingButton fullWidth variant="contained" onClick={handleMock} loading={settleIsLoading} disabled={settleIsLoading} loadingPosition="start">
                  Finalizar
                </LoadingButton>
              }
            </Box>

          </div>
        </Box>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <WarningModal onConfirm={handleQRCode} onDismiss={() => setOpen(false)} />
      </Dialog>
    </div>
  )
}

export default ShelfReceipt