import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: '',
  name: '',
  store_name: '',
  address: {
    street: '',
    number: '',
    city: '',
    state: '',
    country: '',
    municipality: '',
    geo: {
      lat: '',
      lng: ''
    }
  },
  shelf_number: '',
  images: [],
  weekly_status: '',
  streets: '',
  risponsable: '',
  phone: '',
  rate: null,
}

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStore: (state, { payload }) => {
      // console.log('setStore', payload)
      return {
        ...payload,
        name: payload?.name || payload?.store_name,
      }
    },
    clearStoreState: (state) => {
      return initialState
    }
  },
})

export const { setStore, clearStoreState } = storeSlice.actions
export default storeSlice.reducer
