import { COLORS } from '../../styles/colors'

const styles = {
  scrollView: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 24,
    paddingBottom: 24,
    flexGrow: 1,
    flex: 1,
    backgroundColor: COLORS.lightgrey,
    minHeight: '100%',
  },
  secondaryBtn: {
    marginTop: 10,
    marginBottom: 10
  },
  primaryBtn: {
    marginTop: '40px',
  },
  cameraBtn: {
    marginTop: 16,
    marginBottom: 16,
  },
}

export default styles
