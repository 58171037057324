import { COLORS } from 'styles/colors'
import styled from 'styled-components'

export const TotalWrapper = styled.div`
  display: inline-block;
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  text-align: center;
  min-height: 20px;
`

const styles = {
  scrollView: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 24,
    paddingBottom: 24,
    flexGrow: 1,
    backgroundColor: COLORS.lightgrey,
    minHeight: '100%',
  },
  secondaryBtn: {
    marginTop: 10,
    marginBottom: 10
  },
  primaryBtn: {
    marginTop: 'auto'
  }
}

export default styles
