import styled from 'styled-components'

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
`

export const CentetedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px 0px 8px 8px;
`

const styles = {
  image: {
    width: 80,
    minHeight: 90,
    height: '100%',
  },
  circle: {
    marginRight: 8,
  }
}

export default styles
