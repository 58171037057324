export const COLORS = {
  white: '#fafaff',
  white_2: '#f5fcff',
  black: '#000000',
  main_1: '#6533ff',
  main_2: '#ef2b43',
  main_3: '#2fb1ea',
  main_4: '#33e583',
  main_5: '#ffd522',
  main_6: '#78CC8F',
  main_ligth_1: '#b19ffc',
  main_ligth_2: '#f98598',
  main_ligth_4: '#7df9b2',
  main_ligth_5: '#ffe473',
  main_dark_1: '#09064f',
  main_dark_2: '#751121',
  main_dark_3: '#17617a',
  main_dark_4: '#16723d',
  main_dark_5: '#7f5416',
  neutral: '#c4bf1a',
  gray_0: '#f7f7f7',
  gray_1: '#ebebeb',
  gray_2: '#A7A7A7',
  gray_3: '#8F8F8F',
  transparent: 'transparent',
  statusBar: '#541DFF',
  primary: '#531AFF',
  success: '#57A800',
  midnight: '#13161F',
  deepblue: '#2828A4',
  lightgrey: '#F2F5FB',
  midgrey: '#D1D5DE',
  hanpurple: '#531AFF',
  peachypink: '#E9A9C1',
  darkgrey: '#6D7487',
  darkgray: '#6D7487',
  oriolesred: '#FF4A1D',
  main_ligth_3: '#CAC4D0',
  m3: '#2F3133',
  whitecloud: '#FDFDFF',
  m3_sys_light: '#49454F',
  goldensun: '#F4D013'
}

export default COLORS