import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getShelfProducts, 
  saveConsignment, 
  qualifyStore, 
  getConsignmentById, 
  updateConsignmentById
} from '../../services/shelf'
import { setStore } from './store'


export const getShelf = createAsyncThunk(
  'shelf/getShelf',
  async ({ shelf_number }, { getState, dispatch }) => {
    const store = getState().store
    const response = await getShelfProducts({ shelf_number })
    if (response?.store_id) {
      await dispatch(setStore({
        id: response?.store_id,
        shelf_number: response?.shelf?.shelf_number,
        ...store,
        ...response
      }))
    }
    return response
  }
)

export const saveReceipt = createAsyncThunk(
  'shelf/saveReceipt',
  async ({ shelf_number, store_id, order_details }, { getState }) => {
    const { token } = getState().identity
    const response = await saveConsignment({ token, shelf_number, store_id, order_details })
    return response
  }
)

export const rateConsignment = createAsyncThunk(
  'shelf/rateConsignment',
  async ({ consignment_id, rate, attribute, comments }, { getState }) => {
    const { token } = getState().identity
    const response = await qualifyStore({ token, consignment_id, rate, attribute, comments })
    if (response.success) {
      return response
    } else {
      throw new Error('Error inesperado')
    }
  }
)

export const getReceiptById = createAsyncThunk(
  'shelf/getReceiptById',
  async ({ consignment_id }, { getState }) => {
    const { token } = getState().identity
    const response = await getConsignmentById({ token, consignment_id })
    return response
  }
)

export const updateReceiptById = createAsyncThunk(
  'shelf/updateReceiptById',
  async ({ consignment_id, data }, { getState }) => {
    const { token } = getState().identity
    const response = await updateConsignmentById({ token, consignment_id, data })
    return response
  }
)

const initialReceipt = {
  id: '',
  code: '',
  shelf_number: '',
  created_date: '',
  vendor_id: '',
  status: '',
  to_pay: 0,
  total_cost: 0,
  estimated_profit: 0,
  order_details: [],
  receipt_user: null,
  store_logo: null,
  store_name: null
}

const initialState = {
  id: '',
  shelf_number: '',
  installation_date: null,
  shelf_dimensions: null,
  shelf_type: '',
  products: [],
  selected_product: null,
  position: 0,
  products_restock: {},
  shelf_not_found: false,
  
  step: 1,
  restock_product: null,

  isLoading: false,
  error: null,

  receipt: initialReceipt,

  shelf_init_image: null,
  shelf_finish_image: null,
  shelf_product_image: null,
  shelf_product_refilled_image: null,
  shelf_recall_image: null,

  receiptIsLoading: false,
  receiptError: null,

  rateIsLoading: false,
  rateError: null,
  rateSuccess: null,

  notification_key: null,
  notification_path: null,
}

const shelfSlice = createSlice({
  name: 'shelf',
  initialState,
  reducers: {
    setInitImage: (state, action) => {
      state.shelf_init_image = action.payload
    },
    setFinishImage: (state, action) => {
      state.shelf_finish_image = action.payload
    },
    setProductImage: (state, action) => {
      state.shelf_product_image = action.payload
    },
    setProductRefilledImage: (state, action) => {
      state.shelf_product_refilled_image = action.payload
    },
    setRecallImage: (state, action) => {
      state.shelf_recall_image = action.payload
    },
    // setProductPosition: (state, action) => {
    //   state.position = action.payload
    // },
    setSelectProduct: (state, action) => {
      state.selected_product = action.payload
    },
    clearReceipt: (state) => {
      console.log('clearReceipt')
      state.products_restock = {}
      state.receipt = initialReceipt
      state.receiptIsLoading = false
      state.receiptError = null

      state.shelf_init_image = null
      state.shelf_finish_image = null
      state.shelf_product_image = null
      state.shelf_product_refilled_image = null
      state.shelf_recall_image = null
    },
    resetReceipt: (state) => {
      state.receipt = initialReceipt
      state.receiptIsLoading = false
      state.receiptError = null
    },
    setReceiptStatus: (state, action) => {
      state.receipt.status = action.payload
    },
    setNotificationKey: (state, action) => {
      const { notification_key, notification_path } = action.payload
      state.notification_key = notification_key
      state.notification_path = notification_path
    },
    // setNextStep: (state) => {
    //   state.position = state.position + 1
    // },
    // setPrevStep: (state) => {
    //   state.position = state.position - 1
    // },
    setStep: (state, { payload }) => {
      state.step = payload.step
      state.restock_product = { ...state.restock_product, ...payload.restock_product}
    },
    resetStep: (state) => {
      state.step = 1
      state.restock_product = null
    },
    addRestockProduct: (state, { payload }) => {
      state.products_restock[payload.sku] = payload
      // state.products_restock = [...state.products_restock, payload]
    },
    clearRestockSteps: (state) => {
      // state.position = 0
      state.step = 1
      state.shelf_product_image = null
      state.shelf_product_refilled_image = null
    },
    clearShelfNotFound: (state) => {
      state.shelf_not_found = false
    },
    clearShelfState: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getShelf.pending, (state) => {
      state.isLoading = true
      state.error = null
      state.shelf_not_found = false
    })
    builder.addCase(getShelf.fulfilled, (state, { payload }) => {
      const shelf = payload?.shelf
      console.log('getShelf.fulfilled')
      state.isLoading = false
      if (shelf) {
        state.shelf_number = shelf?.shelf_number
        state.id = shelf?.id
        // state.products = shelf?.products
        state.products = shelf?.products
      } else {
        state.shelf_not_found = true
      }
    })
    builder.addCase(getShelf.rejected, (state, { error }) => {
      console.log(error.message)
      state.isLoading = false
      state.error = error.message
    })
    
    builder.addCase(saveReceipt.pending, (state) => {
      state.receiptIsLoading = true
      state.receiptError = null
    })
    builder.addCase(saveReceipt.fulfilled, (state, { payload }) => {
      const receipt = payload?.data
      console.log('saveReceipt.fulfilled')
      state.receipt.id = receipt?.id
      state.receipt.code = receipt?.code
      state.receipt.shelf_number = receipt?.shelf_number
      state.receipt.created_date = receipt?.created_date
      state.receipt.vendor_id = receipt?.vendor_id
      state.receipt.status = receipt?.status
      state.receipt.to_pay = receipt?.to_pay
      state.receipt.total_cost = receipt?.total_cost
      state.receipt.estimated_profit = receipt?.estimated_profit
      state.receipt.order_details = receipt?.order_details
      state.receipt.receipt_user = receipt?.receipt_user
      state.receipt.store_logo = receipt?.store_logo
      state.receipt.store_name = receipt?.store_name

      state.receiptError = null
      state.receiptIsLoading = false
    })
    builder.addCase(saveReceipt.rejected, (state, { error }) => {
      console.log('saveReceipt.rejected', error.message)
      state.receiptIsLoading = false
      state.receiptError = error.message
    })

    builder.addCase(rateConsignment.pending, (state) => {
      state.rateIsLoading = true
      state.rateError = null
      state.rateSuccess = null
    })
    builder.addCase(rateConsignment.fulfilled, (state, action) => {
      console.log('rateConsignment', action.payload)
      state.rateSuccess = true
      state.rateIsLoading = false
      state.rateError = null
    })
    builder.addCase(rateConsignment.rejected, (state, action) => {
      console.log(action)
      state.rateSuccess = null
      state.rateIsLoading = false
      state.rateError = action.error.message
    })

    builder.addCase(getReceiptById.pending, (state) => {
      state.receiptIsLoading = true
      state.receiptError = null
    })
    builder.addCase(getReceiptById.fulfilled, (state, { payload }) => {
      // console.log('getReceiptById.fulfilled', payload)
      const receipt = payload?.data
      state.receipt.status = receipt?.status

      state.receiptError = null
      state.receiptIsLoading = false
    })
    builder.addCase(getReceiptById.rejected, (state, { error }) => {
      console.log('getReceiptById.rejected', error)
      state.receiptIsLoading = false
      state.receiptError = error.message
    })

    builder.addCase(updateReceiptById.pending, (state) => {
      state.receiptIsLoading = true
      state.receiptError = null
    })
    builder.addCase(updateReceiptById.fulfilled, (state, { payload }) => {
      console.log('updateReceiptById.fulfilled', payload)
      const receipt = payload?.data
      // state.receipt.status = receipt?.status

      state.receiptError = null
      state.receiptIsLoading = false
    })
    builder.addCase(updateReceiptById.rejected, (state, { error }) => {
      console.log('updateReceiptById.rejected', error)
      state.receiptIsLoading = false
      state.receiptError = error.message
    })
  }
})

export const {
  setInitImage,
  setFinishImage,
  setRecallImage,
  // setNextStep,
  // setPrevStep,
  // setProductPosition,
  setNotificationKey,
  setReceiptStatus,
  resetReceipt,
  clearReceipt,
  setSelectProduct,
  clearRestockSteps,
  addRestockProduct,
  setStep,
  resetStep,
  setProductImage,
  setProductRefilledImage,
  clearShelfState,
  clearShelfNotFound
} = shelfSlice.actions

export default shelfSlice.reducer
