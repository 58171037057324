import { ChevronLeft } from '@mui/icons-material'

function BackButton({ onPress }) {
  return (
    <div onClick={onPress}>
      <ChevronLeft fontSize="large" />
    </div>
  )
}

export default BackButton