// import { useSelector, useDispatch } from 'react-redux'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  Box, 
  Checkbox, 
  IconButton, 
  InputAdornment, 
  FormControlLabel, 
  FormControl 
} from '@mui/material'

import Input from 'components/Input'
import RadioGroup from 'components/RadioGroup'
// import ImageThumbnail from 'components/ImageThumbnail'
// import { CAMERA_TYPES } from 'constants/types'
// import { setProductImage } from 'store/slices/shelf'
// import styles from './styles'
// import colors from '../../styles/colors'

function Step2({ control, setValue, getValues, watch, navigation, showExpiredWasteInputs }) {
  // const dispatch = useDispatch()
  // const watchExpiredWaste = watch('expired_waste')
  // const { shelf_product_image } = useSelector(state => state.shelf)

  const handleAllWaste = (e) => {
    if (e.target.checked) {
      const existing = getValues('existing')
      setValue('waste', existing)
      setValue('expired', '0')
    } else {
      setValue('waste', '')
      setValue('expired', '')
    }
  }

  return (
    <div>
      {!showExpiredWasteInputs &&
        <>
          <div className="headlineSmall">
            ¿Hay productos mermados o expirados?
          </div>
          <RadioGroup
            control={control}
            name="expired_waste"
            label=""
            rules={{ required: true }}
            options={[
              { label: 'Si', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
          />
        </>
      }
      {showExpiredWasteInputs && (
        <div>
          <div className="headlineSmall">
            Cuenta cuantas piezas hay de cada uno y retíralas
          </div>
          <Input
            name="waste"
            control={control}
            label="Piezas mermadas"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="borrar valor"
                  onClick={() => setValue('waste', '')}
                  edge="end"
                >
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            }
            rules={{
              required: true,
              validate: {
                numbers: (value) => Number.isInteger(Number(value)) || 'Debe contener solo números enteros',
                positive: (value) => Number(value) >= 0 || 'Debe ser un número positivo'
              }
            }}
          />
          <Input
            name="expired"
            control={control}
            label="Piezas expiradas"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="borrar valor"
                  onClick={() => setValue('expired', '')}
                  edge="end"
                >
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            }
            rules={{
              required: true,
              validate: {
                numbers: (value) => Number.isInteger(Number(value)) || 'Debe contener solo números enteros',
                positive: (value) => Number(value) >= 0 || 'Debe ser un número positivo'
              }
            }}
          />
          <Box>
            <FormControlLabel control={<Checkbox onChange={handleAllWaste} />} label="Retirar todo como merma" />
          </Box>
          {/* {!shelf_product_image ? (
            <Button
              icon="camera"
              onClick={() => navigation.navigate('CaptureImage', { type: CAMERA_TYPES.PRODUCT_IMAGE })}
              textColor={colors.deepblue}
              style={styles.cameraBtn}
            >
              Tomar foto de Producto
            </Button>
          ) : (
            <ImageThumbnail
              uri={shelf_product_image?.url}
              name={shelf_product_image.name} 
              onClick={() => dispatch(setProductImage(null))}
            />
          )} */}
        </div>
      )}
    </div>
  )
}

export default Step2
