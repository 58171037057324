import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import Input from 'components/Input'

function Step4({ control, setValue, showReason }) {
  if (!showReason) {
    return (
      <div>
        <div className="headlineSmall">
          ¿Cuántos productos dejas en anaquel?
        </div>
        <Input
          name="custom_restock"
          control={control}
          label="Cantidad de piezas"
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="borrar valor"
                onClick={() => setValue('custom_restock', '')}
                edge="end"
              >
                <HighlightOffIcon />
              </IconButton>
            </InputAdornment>
          }
          rules={{
            required: true,
            validate: {
              numbers: (value) => Number.isInteger(Number(value)) || 'Debe contener solo números enteros',
              positive: (value) => Number(value) >= 0 || 'Debe ser un número positivo'
            }
          }}
        />
      </div>
    )
  } else {
    return (
      <div>
        <div className="headlineSmall">
          ¿Cuál es la razón por la que no tienes producto?
        </div>
        <Input
          name="no_restock_reason"
          control={control}
          label="Escribe el motivo"
          rules={{ required: true }}
        />
      </div>
    )
  }
}

export default Step4