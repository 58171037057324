// utils/statusColor.js
import { COLORS } from '../styles/colors'

const statusInfo = (status) => {
  switch (status) {
    case 'in_stock':
      return { color: COLORS.success, label: 'Con inventario', textColor: COLORS.white }
    case 'low_stock':
      return { color: COLORS.goldensun, label: 'Poco inventario', textColor: COLORS.black }
    case 'off_stock':
    case 'out_of_stock':
      return { color: COLORS.oriolesred, label: 'Sin inventario', textColor: COLORS.white }
    default:
      return { color: '#CCCCCC', label: 'Sin estado', textColor: COLORS.black }
  }
}

export const mapOrderType = (status) => {
  switch (status) {
    case 'restock': {
      return { label: 'Entrada de inventario', textColor: COLORS.success }
    }
    case 'recall': {
      return { label: 'Retiro de inventario', textColor: COLORS.oriolesred }
    }
  }
}

export default statusInfo