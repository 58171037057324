export const CAMERA_TYPES = {
  INITIAL_IMAGE: 'INITIAL_IMAGE',
  FINAL_IMAGE: 'FINAL_IMAGE',
  PRODUCT_IMAGE: 'PRODUCT_IMAGE',
  PRODUCT_REFILLED_IMAGE: 'PRODUCT_REFILLED_IMAGE',
  PRODUCT_RECALLED_IMAGE: 'PRODUCT_RECALLED_IMAGE',
}

export const RESTOCK_STATE = {
  YES: 'yes',
  NO: 'no',
  NOT_ENOUGH: 'not_enough',
}

export const RECEIPT_STATUS = {
  INIT: 'init',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
  FULFILLED: 'fulfilled',
}

export const VENDOR_TYPE = {
  WAREHOUSE: 'warehouse_delivery',
  PRODUCER: 'producer',
  DELIVERY: 'producer_delivery',
}
