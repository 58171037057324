import request from '../utils/request'

export const getShelfProducts = async ({ shelf_number }) => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/vendor/shelf/${shelf_number}`
  })
  return response?.data
}

export const saveConsignment = async ({ shelf_number, store_id, order_details }) => {
  // console.log('REQ', JSON.stringify({
  //   store_id,
  //   shelf_number,
  //   order_details
  // }))
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/vendor/consignment`,
    data: {
      store_id,
      shelf_number,
      order_details
    }
  })
  return response?.data
}

export const qualifyStore = async ({ consignment_id, rate, attribute, comments }) => {
  const response = await request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/vendor/nps`,
    data: {
      id: consignment_id,
      rate,
      attribute,
      comments
    }
  })
  return response?.data
}

export const getConsignmentById = async ({ consignment_id }) => {
  const response = await request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/vendor/consignment/${consignment_id}`,
  })
  return response?.data
}

export const updateConsignmentById = async ({ consignment_id, data }) => {
  console.log('REQ UPDATE',{
    id: consignment_id,
    ...data
  })
  const response = await request({
    method: 'PUT',
    url: `${process.env.REACT_APP_API_URL}/vendor/consignment`,
    data: {
      id: consignment_id,
      ...data
    },
  })
  return response?.data
}