const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={100}
    height={100}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#fff" d="M0 0h100v100H0z" />
      <path fill="#D1D5DE" d="M0 0h100v100H0z" />
      <path
        fill="#FDFDFF"
        d="M27.334 31.583v-4.25h45.474v4.25H27.333Zm.354 41.084V54.392h-3.471v-4.25l3.117-14.309h45.404l3.116 14.309v4.25h-3.47v18.275h-4.25V54.392H54.745v18.275H27.688Zm4.25-4.25h18.558V54.392H31.938v14.025Zm-3.542-18.275h43.28l-2.197-10.059H30.592l-2.196 10.059Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;