import ChevronRight from '@mui/icons-material/ChevronRight'
import { ButtonCardWrapper, ContentWrapper } from './styles'

function ButtonCard({ children, onPress, radius, margin, padding, hideAction }) {
  return (
    <div onClick={onPress}>
      <ButtonCardWrapper radius={radius} margin={margin} padding={padding}>
        <ContentWrapper>
          {children}
        </ContentWrapper>
        {!hideAction && (
          <div>
            <ChevronRight fontSize="large" />
          </div>
        )}
      </ButtonCardWrapper>
    </div>
  )
}

export default ButtonCard
