import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

import Home from '../pages/Home'
import Login from '../pages/Login'
import StoreInfo from '../pages/StoreInfo'
import ConsignmentDetail from '../pages/ConsignmentDetail'
import Restock from '../pages/Restock'
import ShelfReceipt from '../pages/ShelfReceipt'
import ShelfReceiptQR from '../pages/ShelfReceiptQR'
import NPS from '../pages/NPS'
import Recall from '../pages/Recall'

const ProtectedRoutes = ({ children }) => {
  const { isAuth } = useSelector((state) => state.identity)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuth) {
      navigate('/login')
    }
  }, [isAuth, navigate])

  return isAuth ? children : null
}

function AppRouter() {
  // const ProtectedRoute = ({ element, ...rest }) => {
  //   if (isAuth) {
  //     return <Route {...rest} element={element} />
  //   } else {
  //     return <Navigate to="/login" replace />
  //   }
  // }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={
          <ProtectedRoutes>
            <Home />
          </ProtectedRoutes>
        } />
        <Route path="/StoreInfo" element={
          <ProtectedRoutes>
            <StoreInfo />
          </ProtectedRoutes>
        } />
        <Route path="/ConsignmentDetail" element={
          <ProtectedRoutes>
            <ConsignmentDetail />
          </ProtectedRoutes>
        } />
        <Route path="/Restock" element={
          <ProtectedRoutes>
            <Restock />
          </ProtectedRoutes>
        } />
        <Route path="/ShelfReceipt" element={
          <ProtectedRoutes>
            <ShelfReceipt />
          </ProtectedRoutes>
        } />
        <Route path="/ShelfReceiptQR" element={
          <ProtectedRoutes>
            <ShelfReceiptQR />
          </ProtectedRoutes>
        } />
        <Route path="/NPS" element={
          <ProtectedRoutes>
            <NPS />
          </ProtectedRoutes>
        } />
        <Route path="/Recall" element={
          <ProtectedRoutes>
            <Recall />
          </ProtectedRoutes>
        } />
      </Routes>
    </Router>
  )
}

export default AppRouter