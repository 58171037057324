import styles, { CardContent, CentetedRow, DescriptionWrapper } from './styles'
import colors from '../../styles/colors'
import statusInfo from '../../utils/status'
import { StatusCircle } from '../../styles/shared'

const StoreCard = ({ store }) => {
  const { street, ext_number, neighborhood, state, municipality } = store?.address || {}
  const statusStyle = statusInfo(store?.inventory_status)
  // console.log(store?.inventory_status)

  return (
    <CardContent>
      <img src={store.images?.[0]?.front_view} style={styles.image} alt="Foto de tienda" />
      <DescriptionWrapper>
        <div className="bodyLarge" style={{ color: colors?.deepblue, marginBottom: '5px' }} /* ellipsizeMode="tail" numberOfLines={1} */>
          {store.name}
        </div>
        <p className="bodyMedium" /* ellipsizeMode="tail" numberOfLines={1} */ style={{ marginBottom: '10px' }}>
          {`${street} ${ext_number}, ${neighborhood}, ${municipality}, ${state}`}
        </p>
        <CentetedRow>
          <StatusCircle color={statusStyle.color} style={styles.circle} />
          <p className="bodySmall">
            {statusStyle.label}
          </p>
        </CentetedRow>
      </DescriptionWrapper>
    </CardContent>
  );
};

export default StoreCard
