import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import vendorSlice from './slices/vendor'
import identitySlice from './slices/identity'
import storeSlice from './slices/store'
import storesSlice from './slices/stores'
import shelfSlice from './slices/shelf'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['vendor', 'store', 'stores', 'shelf'],
  blacklist: ['identity']
}

const rootReducer = combineReducers({
  vendor: vendorSlice,
  identity: identitySlice,
  store: storeSlice,
  stores: storesSlice,
  shelf: shelfSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }
  ),
})

export const persistor = persistStore(store)