import { useState } from 'react'
import styled from 'styled-components'
import { Button, Box } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import DialogActions from '@mui/material/DialogActions'
import { LoadingButton } from '@mui/lab'

import colors from 'styles/colors'

const WarningModalWrapper = styled.div`
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  margin-left: 16px;
  margin-right: 16px;
`

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`

const styles = {
  grayBold: {
    color: colors.gray_4,
    fontWeight: 'bold',
  },
  grayBoldUnderline: {
    color: colors.gray_4,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
}

function WarningModal({ onDismiss, onConfirm, existing }) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <WarningModalWrapper>
      <ModalHeader>
        <WarningAmberIcon fontSize="large" color="warning" />
        <p className="headlineSmall">Discrepancia en conteo</p>
      </ModalHeader>
      <Box mb={2} mt={3}>
        <p className="bodyLarge" style={{ color: colors.gray_4 }}>
          Al{' '}<span style={styles.grayBold}>continuar</span>{' '}confirmas que hay{' '}<span style={styles.grayBoldUnderline}>{existing}</span>{' '}piezas en el anaquel.
          {'\n\n'}
          <span style={styles.grayBold}>Esta acción modificará tus ventas y existencias.</span>
          {'\n\n'}
        </p>
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={() => onDismiss()} disabled={isLoading}>
          Revisar
        </Button>
        <LoadingButton
          onClick={() => {
            setIsLoading(true)
            onConfirm()
          }}
          variant="contained"
          disabled={isLoading}
          loading={isLoading}
        >
          Si, continuar
        </LoadingButton>
      </DialogActions>
    </WarningModalWrapper>
  )
}

export default WarningModal
