import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getStoresList, filterStores } from '../../services/stores'

export const getStores = createAsyncThunk(
  'stores/getStores',
  async (_, { getState }) => {
    const { token } = getState().identity
    const response = await getStoresList({ token })
    return response
  }
)

export const getFilteredStores = createAsyncThunk(
  'stores/getFilteredStores',
  async ({ inventory_state, zone, name = '' }, { getState }) => {
    console.log('stores/getFilteredStores', {inventory_state, zone, name})
    const { token } = getState().identity
    const response = await filterStores({ token, inventory_state, zone, name })
    return {
      data: response,
      filters: {
        inventory_state,
        zone,
        name
      }
    }
  }
)

const storesSlice = createSlice({
  name: 'stores',
  initialState: {
    stores: [],
    isLoading: false,
    error: null,
    
    filtered: false,
    filter_inventory_state: 'all',
    filter_zone: [],
    filter_name: '',
  },
  reducers: {
    setFilters: (state, { payload }) => {
      console.log('setFilters', payload)
      state.filter_inventory_state = payload?.inventory_state
      state.filter_zone = payload?.zone
      state.filter_name = payload?.name
      state.stores = []
      
    },
    clearFilters: (state) => {
      state.filter_inventory_state = 'all'
      state.filter_zone = []
      state.filter_name = ''
    },
    clearFiltered: (state) => {
      state.filtered = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getStores.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getStores.fulfilled, (state, { payload }) => {
      console.log('getStores')
      state.isLoading = false
      state.stores = payload.stores
    })
    builder.addCase(getStores.rejected, (state, { error }) => {
      state.isLoading = false
      state.error = error.message
    })
    
    builder.addCase(getFilteredStores.pending, (state) => {
      console.log('getFilteredStores.pending')
      state.filtered = false
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getFilteredStores.fulfilled, (state, { payload }) => {
      const { filters, data } = payload
      console.log('getFilteredStores.fulfilled', filters)
      state.filtered = true
      state.isLoading = false
      state.stores = data?.stores

      state.filter_inventory_state = filters?.inventory_state
      state.filter_zone = filters?.zone
      state.filter_name = filters?.name
    })
    builder.addCase(getFilteredStores.rejected, (state, { error }) => {
      console.log('getFilteredStores.rejected', error)
      state.filtered = false
      state.isLoading = false
      state.error = error.message
    })
  }
})

export const { setFilters, clearFilters, clearFiltered } = storesSlice.actions
export default storesSlice.reducer
