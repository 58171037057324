import styled from 'styled-components'

export const ButtonCardWrapper = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: ${props => props.radius || 0}px;
  margin-bottom: ${props => props.margin?.b || 0}px;
  margin-top: ${props => props.margin?.t || 0}px;
  margin-left: ${props => props.margin?.l || 0}px;
  margin-right: ${props => props.margin?.r || 0}px;
  
  padding-bottom: ${props => props.padding?.b || 0}px;
  padding-top: ${props => props.padding?.t || 0}px;
  padding-left: ${props => props.padding?.l || 0}px;
  padding-right: ${props => props.padding?.r || 0}px;
  overflow: hidden;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`