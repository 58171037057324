import styled from 'styled-components'
import { Button, Box } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import DialogActions from '@mui/material/DialogActions'

import colors from 'styles/colors'

const WarningModalWrapper = styled.div`
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  margin-left: 16px;
  margin-right: 16px;
`

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`

function WarningModal({ onDismiss, onConfirm }) {
  return (
    <WarningModalWrapper>
      <ModalHeader>
        <WarningAmberIcon fontSize="large" color="warning" />
        <p className="headlineSmall">¡Importante!</p>
      </ModalHeader>
      <Box mb={2} mt={3}>
        <p className="bodyLarge" style={{ color: colors.gray_4 }}>
          Al{' '}<span style={{ color: colors.gray_4, fontWeight: 'bold'}}>continuar</span>{' '}confirmas que la información ingresada es correcta y por tanto tus ventas reconocidas. {'\n\n'}
          Si olvidas registrar piezas,{' '}<span style={{ color: colors.gray_4, fontWeight: 'bold' }}>no nos responsabilizaremos por unidades faltantes, lo que podría afectar tus ventas.</span>{'\n\n'}
        </p>
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={() => onDismiss()}>
          Revisar Surtido
        </Button>
        <Button onClick={() => onConfirm()} variant="contained">
          Generar QR
        </Button>
      </DialogActions>
    </WarningModalWrapper>
  )
}

export default WarningModal