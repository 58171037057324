import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import QRCode from 'react-qr-code'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'

import {
  Header,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
} from 'styles/shared'
import styles from './styles'
import BackButton from 'components/BackButton'
import colors from 'styles/colors'

import { getReceiptById } from '../../store/slices/shelf'
import { RECEIPT_STATUS } from '../../constants/types'

const ShelfReceiptQR = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { receipt: { id: receipt_id, status, code }, receiptIsLoading } = useSelector(state => state.shelf)
  // const { navigateNPS } = useSelector(state => state.vendor)

  const onRefresh = useCallback(() => {
    dispatch(getReceiptById({ consignment_id: receipt_id }))
  }, [])

  useEffect(() => {
    if (status && status !== RECEIPT_STATUS.INIT) {
      navigate('/NPS')
    }
  }, [status])
  
  return (
    <div className="container">
      <Header margin={{ b: 20 }}>
        <HeaderLeft>
          <BackButton onPress={() => navigate(-1)} />
        </HeaderLeft>
        <HeaderCenter>
          <span className="bodyLarge">
            Resumen
          </span>
        </HeaderCenter>
        <HeaderRight>
        </HeaderRight>
      </Header>
      <Box className="main">
        <Box className="bodyLarge" style={{ color: colors.darkgrey }} pb={2}>
          Muestra para escanear{'\n'}
        </Box>
        <div style={styles.card}>
          {receipt_id && <QRCode value={receipt_id} style={{ height: "auto", maxWidth: "300px", width: "70%" }} />}
        </div>
        <Box className="bodyLarge" style={{ color: colors.darkgrey }} pb={2}>
          O comparte el siguiente código:{'\n'}
        </Box>
        <div style={styles.card} pb={2}>
          {code && 
            <div className="headlineLarge" style={styles.code}>
              {code}
            </div>
          }
        </div>
        {(status === RECEIPT_STATUS.INIT || !status) &&
          <LoadingButton 
            variant="contained" 
            fullWidth
            onClick={onRefresh} 
            style={styles.btn} 
            disabled={receiptIsLoading}
            loading={receiptIsLoading}
            loadingPosition="start"
          >
            Verificar
          </LoadingButton>
        }
      </Box>
    </div>
  )
}

export default ShelfReceiptQR
