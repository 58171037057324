import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import * as SecureStore from 'expo-secure-store'
// import AsyncStorage from '@react-native-async-storage/async-storage'

import {
  authenticate, 
  setAccountPassword, 
  passwordRecover, 
  resetPassword, 
  updatePassword,
  getUser
} from '../../services/identity'

export const getLocalUserData = createAsyncThunk(
  'identity/getLocalUserData',
  async () => {
    const token = localStorage.getItem('token')
    if (token) {
      return token
    } else {
      throw new Error('No se está autenticado')
    }
  }
)

export const logout = createAsyncThunk(
  'identity/logout',
  async () => {
    localStorage.setItem('token', '')
    localStorage.clear()
  }
)

export const login = createAsyncThunk(
  'identity/login',
  async ({ username, password, biometrics }) => {
    const res = await authenticate({ username, password })
    if (res?.data?.token) {
      localStorage.setItem('token', res?.data?.token)
      return {
        token: res?.data?.token
      }
    } else {
      throw new Error('Usuario o contraseña son incorrectos')
    }
  }
)

export const setPassword = createAsyncThunk(
  'identity/setPassword',
  async ({ id, password, user_type }) => {
    const res = await setAccountPassword({ id, password, user_type })
    if (res.success) {
      return { user_type }
    } else {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

export const recoverPassword = createAsyncThunk(
  'identity/recoverPassword',
  async ({ email }) => {
    const res = await passwordRecover({ email })
    if (res) {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

export const restorePassword = createAsyncThunk(
  'identity/restorePassword',
  async ({ deeplink_id, password }) => {
    const res = await resetPassword({ deeplink_id, password })
    if (res.success) {
      return { success: res.success }
    } else {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

export const changePassword = createAsyncThunk(
  'identity/changePassword',
  async ({ username, old_password, new_password }) => {
    const res = await updatePassword({ username, old_password, new_password })
    if (res.success) {
      return { success: res.success }
    } else {
      throw new Error('No se pudo completar el proceso')
    }
  }
)

export const setUser = createAsyncThunk(
  'identity/user',
  async () => {
    const response = await getUser()
    return response
  }
)

const initialState = {
  isAuth: localStorage.getItem('token') || null,
  token: '',
  isLoading: false,
  error: null,

  password: {
    isLoading: false,
    error: null,
    success: false,
    user_type: ''
  },
  passwordRecovery: {
    isLoading: false,
    error: null,
    success: false,
  },
  passwordChange: {
    isLoading: false,
    error: null,
    success: false,
  },
  // passwordRestoring: {
  //   isLoading: false,
  //   error: null,
  //   success: false,
  // },
  passRestIsLoading: false,
  passRestError: null,
  passRestSuccess: false,
  user:{
    alias:''
  }
}

const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    clearPasswordChange: (state) => {
      state.passwordChange.error = null
      state.passwordChange.success = false
      state.passwordChange.isLoading = false
    },
    clearIdentityState: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLocalUserData.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getLocalUserData.fulfilled, (state, action) => {
      state.isAuth = true
      state.isLoading = false
      state.error = null
      state.token = action.payload
    })
    builder.addCase(getLocalUserData.rejected, (state, action) => {
      state.isAuth = false
      state.isLoading = false
      state.error = action.error.message
    })

    builder.addCase(logout.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoading = false
      state.isAuth = false
      state.token = ''
    })
    builder.addCase(logout.rejected, (state, action) => {
      state.isLoading = false
      state.isAuth = false
      state.error = action.error.message
    })

    builder.addCase(login.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false
      state.isAuth = true
      state.token = action.payload?.token || ''
      state.user = action.payload?.user || null
    })
    builder.addCase(login.rejected, (state, action) => {
      // console.log('login.rejected', JSON.stringify(action.error))
      state.isLoading = false
      state.isAuth = false
      if (action?.error?.code === 'ERR_BAD_RESPONSE') {
        state.error = 'Algo salió mal, por favor intenta de nuevo'
      } else if (action?.error?.code === 'ERR_BAD_REQUEST') {
        state.error = 'Usuario o contraseña son incorrectos'
      } else {
        state.error = action.error.message
      }
    })

    builder.addCase(setPassword.pending, () => {
      return {
        password: {
          success: false,
          isLoading: true
        } 
      }
    })
    builder.addCase(setPassword.fulfilled, (state, action) => {
      console.log('setPassword.fulfilled', action.payload)
      return {
        password: {
          user_type: action.payload.user_type,
          isLoading: false,
          error: null,
          success: true,
        } 
      }
    })
    builder.addCase(setPassword.rejected, (state, action) => {
      console.log('setPassword', action)
      return {
        password: {
          isLoading: false,
          error: action.error.message,
          success: false
        } 
      }
    })

    builder.addCase(recoverPassword.pending, () => {
      return {
        passwordRecovery: {
          success: false,
          isLoading: true
        } 
      }
    })
    builder.addCase(recoverPassword.fulfilled, (state, action) => {
      console.log('recoverPassword.fulfilled', action.payload)
      return {
        passwordRecovery: {
          isLoading: false,
          error: null,
          success: true,
        } 
      }
    })
    builder.addCase(recoverPassword.rejected, (state, action) => {
      console.log('recoverPassword', action)
      return {
        passwordRecovery: {
          isLoading: false,
          error: action.error.message,
          success: false
        } 
      }
    })

    builder.addCase(restorePassword.pending, (state) => {
      state.passRestError = null
      state.passRestSuccess = false
      state.passRestIsLoading = true
    })
    builder.addCase(restorePassword.fulfilled, (state, action) => {
      console.log('restorePassword.fulfilled', action.payload)
      state.passRestError = null
      state.passRestSuccess = true
      state.passRestIsLoading = false
    })
    builder.addCase(restorePassword.rejected, (state, action) => {
      console.log('restorePassword', action)
      state.passRestError = action.error.message
      state.passRestSuccess = false
      state.passRestIsLoading = false
    })

    builder.addCase(changePassword.pending, (state) => {
      state.passwordChange.error = null
      state.passwordChange.success = false
      state.passwordChange.isLoading = true
    })
    builder.addCase(changePassword.fulfilled, (state, action) => {
      console.log('changePassword.fulfilled', action.payload)
      state.passwordChange.error = null
      state.passwordChange.success = true
      state.passwordChange.isLoading = false
    })
    builder.addCase(changePassword.rejected, (state, action) => {
      console.log('changePassword', action)
      state.passwordChange.error = action.error.message
      state.passwordChange.success = false
      state.passwordChange.isLoading = false
    })
    builder.addCase(setUser.fulfilled, (state, action) => {
      state.user = action.payload || null;
    })
  },
})

export const { clearPasswordChange, clearIdentityState } = identitySlice.actions
export default identitySlice.reducer