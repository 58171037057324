import styled from 'styled-components'
import { COLORS } from 'styles/colors'

export const TransactionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

export const CenterWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ImageWrapper = styled.div`
  display: flex;
  padding-top: 32px;
  padding-bottom: 100px;
  align-items: center;
  justify-content: center;
`

const styles = {
  card: {
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 16,
    backgroundColor: 'white',
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    paddingTop: 24,
    flexGrow: 1,
  },
  scrollView: {
    padding: 24,
    backgroundColor: COLORS.lightgrey,
    flexGrow: 1,
    minHeight: '100vh',
  },
  optionText: {
    color: COLORS.deepblue,
  },
  contentBottom: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingTop: 30
  }
}

export default styles