import { COLORS } from '../../styles/colors'

const styles = {
  scrollView: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 24,
    paddingBottom: 24,
    flexGrow: 1,
    backgroundColor: COLORS.lightgrey,
    minHeight: '100%',
  },
  body: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  cameraBtn: {
    marginTop: 16,
    marginBottom: 16,
  },
  secondaryBtn: {
    marginBottom: 10
  },
  recallTitle: {
    fontWeight: 'bold',
    color: COLORS.oriolesred
  },
  restockTitle: {
    fontWeight: 'bold',
  },
}

export default styles
