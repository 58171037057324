import { COLORS } from 'styles/colors'

const styles = {
  scrollView: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 24,
    paddingBottom: 24,
    flexGrow: 1,
    height: '100%',
    backgroundColor: COLORS.lightgrey,
  },
  card: {
    display: 'flex',
    backgroundColor: COLORS.white,
    paddingTop: 34,
    paddingBottom: 34,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    marginBottom: 16,
  },
  code: {
    color: COLORS.deepblue,
    textAlign: 'center',
  },
  btn: {
    width: '100%',
    marginTop: 20
  }
}

export default styles
